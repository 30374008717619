<!-- 题库管理首页 -->
<template>
  <div>
    <div class="main" style="display: flex;">
      <div style="width: 100%;height: 100%;display: flex;flex-direction: column;width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;">
        <div style="font-size: 26px; font-weight: bold;display:flex">
          <div style="cursor: pointer;padding: 10px 0;">音频课程</div>
        </div>
        <div style="display: flex;justify-content: space-between;align-items: center;">
          <div style="display: flex;border-radius: 8px 8px 0 0;background: #C6E7E2;line-height: 34px;">
            <div v-for="(item, index) in statusList" :key="index" style="width: 112px;text-align: center;"
              :class="index == courseTitleInd ? 'currentTitle' : 'titlelayout'" @click="courseTitle(index)">{{ item }}
            </div>
          </div>
          <el-button type="primary" style="height: 34px; line-height: 10px;background: #00A78E;" class="cinput"
            @click="addCourseTag">+添加音频</el-button>
        </div>
        <div
          style="height: 60px;background: #fff;display: flex;justify-content: space-between;padding: 0 20px;align-items: center;">
          <el-input placeholder="请输入音频名称" prefix-icon="el-icon-search" v-model="searchStr" style="width: 232px;"
            @keyup.enter.native="getVideoList">
          </el-input>
          <!-- <div style="display: flex;align-items: center;">
            <div style="color: #3D3D3D;font-size: 14px;margin-left: 10px;">品牌:</div>
            <el-select v-model="teacherMain" placeholder="品牌" clearable class="cinput ac" @change='getVideoList'>
              <el-option v-for="item in listTeacher" :key="item.userId" :label="item.name" :value="item.userId">
              </el-option>
            </el-select>
          </div> -->
        </div>
        <el-table :header-cell-style="{ background: '#F7F7F7' }" :data="tableData" style="width: 100%; padding: 20px;"
          @selection-change="handleSelectionChange">

          <el-table-column prop="name" align="center" label="音频名称"></el-table-column>
          <el-table-column prop="weight" align="center" label="权重"></el-table-column>
          <el-table-column prop="name" align="center" label="状态">
            <template slot-scope="scope">
              <div style="display: flex;align-items: center;justify-content: center;"
                v-if="filterOnline(scope.row.onlineTime)">
                <div style="width:10px;height: 10px;border-radius: 50%;background: #52C41A;margin-right: 10px;"></div>
                <div style="color: #52C41A;">已发布</div>
              </div>
              <div style="display: flex;align-items: center;justify-content: center;" v-else>
                <div style="width:10px;height: 10px;border-radius: 50%;background: #FAAD14;margin-right: 10px;"></div>
                <div style="color: #FAAD14;">未发布</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="onlineTime" align="center" label="正式发布时间">
            <template slot-scope="scope">
              <div style="color:#7E8887">
                {{scope.row.onlineTime | onlinTimeFilter}}
              </div>
            </template>
          </el-table-column>
          </el-table-column>
          <el-table-column prop="timeLength" align="center" label="音频时长/s">
            <template slot-scope="scope">
              <div style="color:#7E8887">
                {{scope.row.timeLength | timeLengthFilter}}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="teacherName" align="center" label="操作" width="300">
            <template slot-scope="scope">
              <div style="display: flex; justify-content: center;align-items: center;">
                <el-link type="primary" :underline="false" @click='edit(scope.row)'>详情</el-link>
                <img src="../../images/edit2.png" alt="" style="width: 24px;height: 24px;margin: 0 20px;"
                  @click='edit(scope.row)' />
                <img src="../../images/dell2.png" alt="" style="width: 24px;height: 24px;" @click="dele(scope.row)" />
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div style="
          display: flex;
          align-items: center;
          justify-content: flex-end;
          padding:20px;
          background:#fff;
        ">
          <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="pageIndex" :page-sizes="[10, 15, 20]" :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
        </div>
      </div>
    </div>

    <el-dialog title="添加音频" :visible.sync="dialogVisible" width="60%" class="dialog1" :close-on-click-modal="false">
      <div style="display: flex;justify-content: space-between;height: 100%;">
        <div style="width: 50%;border-right: 1px solid #E5E5E5;">
          <div style="display: flex;align-items: center;font-size: 14px;width: 100px;justify-content: flex-end;">
            <div style="width: 4px;height: 13px;border-radius: 50px;background: #00A78E;margin-right: 4px;"></div>
            <div>基础设置</div>
          </div>
          <div>
            <div style="display: flex;align-items: center;">
              <div style="width: 120px;text-align: right;">音频名称：</div>
              <el-input v-model="name" placeholder="请输入音频名称" style="width: 264px;"></el-input>
            </div>
            <div style="display: flex;align-items: center;margin: 16px 0;">
              <div style="width: 120px;text-align: right;">音频权重：</div>
              <el-input v-model="weight" placeholder="请输入数字" style="width: 104px;"></el-input>
            </div>
            <div style="display: flex;align-items: center;margin: 16px 0;">
            <div style="width: 120px;text-align: right;">品牌:</div>
            <el-select v-model="audioBrandId" placeholder="品牌" clearable class="cinput ac" @change='getAudio' multiple>
              <el-option v-for="item in audiobrandList" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </div>
            <div style="display: flex;margin: 16px 0;">
              <div style="width: 120px;text-align: right;">发布时间：</div>
              <div style="display: flex;flex-direction: column;height: 80px;justify-content: space-around;">
                <el-radio v-model="onlineTimeType" label="1">上传即发布</el-radio>
                <el-radio v-model="onlineTimeType" label="2">
                  <el-date-picker v-model="onlineTime" type="datetime" placeholder="选择日期时间"
                    value-format="yyyy-MM-dd HH:mm:ss">
                  </el-date-picker>
                </el-radio>
              </div>
            </div>
            <div style="display: flex;margin: 16px 0;">
              <div style="width: 120px;text-align: right;">音频上传：</div>
              <!-- <img :src="picUri" alt="" style="width: 104px;height: 104px;" v-if="resourceUrl"> -->

              <input type="file" @change="uploadimgpicUri" id='file2' accept='audio/*' ref='fileimgCourse'
                style="color:#fff" v-if="!resourceUrl" />
              <div style="display: flex;flex-direction: column;">
                <audio :src="resourceUrl" v-if="resourceUrl" controls></audio>
                <el-link type="primary" :underline="false" @click="resourceUrl = ''" v-if="resourceUrl">删除</el-link>
              </div>
            </div>

            <div style="display: flex;align-items: center;font-size: 14px;margin-top: 50px;" v-if="configValue1 == 4">
              <div style="width: 4px;height: 13px;border-radius: 50px;background: #00A78E;margin-right: 4px;">
              </div>
              <div>积分</div>
              <el-input v-model="pricePoint" placeholder="请输入数字" style="width: 104px;"></el-input>
              <div>价格</div>
              <el-input v-model="priceMoney" placeholder="请输入数字" style="width: 104px;"></el-input>
              <div>元</div>
            </div>
          </div>
        </div>
        <div style="width: 50%;">
          <div style="display: flex;align-items: center;font-size: 14px;">
            <div style="width: 4px;height: 13px;border-radius: 50px;background: #00A78E;margin-right: 4px;"></div>
            <div>课程包介绍</div>
          </div>
          <quill-editor style="height:80%;width:100%;" v-model="msg" ref="richAnalysis" :options="options2"
            @change="quillchange"></quill-editor>
          <div style="position: absolute;bottom: 0;display: flex;width: 48%;justify-content: flex-end;">
            <el-button @click="cancelCourse">取消</el-button>
            <el-button style="background: #00A78E;color: #fff;" @click="saveAudio">保存</el-button>
            <input type="file" @change="uploadRichImgMethod" accept='image/*' ref='uploadRichImg' id="uploadRichImg"
              style="display:none" />

          </div>
        </div>

      </div>
    </el-dialog>

    <el-dialog title="添加老师" :visible.sync="dialogVisibleTeacher" width="30%" :before-close="handleCloseTeacher" :close-on-click-modal="false">
      <div>
        <div
          style="display: flex;align-items: center;font-size: 14px;width: 100px;justify-content: flex-end;margin-bottom: 10px;">
          <div style="width: 4px;height: 13px;border-radius: 50px;background: #00A78E;margin-right: 4px;"></div>
          <div>基础设置</div>
        </div>
        <div style="display: flex;align-items: center;">
          <div style="width: 120px;text-align: right;">讲师名称：</div>
          <el-input v-model="name2" placeholder="请输入讲师名称" style="width: 264px;"></el-input>
        </div>
        <div style="display: flex;align-items: center;margin: 20px 0;">
          <div style="width: 120px;text-align: right;">讲师手机：</div>
          <el-input v-model="phone" placeholder="请输入讲师手机号码" style="width: 264px;"></el-input>
        </div>
        <div style="display: flex;">
          <div style="width: 120px;text-align: right;">简介：</div>
          <el-input type="textarea" :rows="5" placeholder="请输入简介" v-model="des" style="width: 264px;" maxlength="108"
            show-word-limit>
          </el-input>
        </div>
        <div
          style="display: flex;align-items: center;font-size: 14px;width: 100px;justify-content: flex-end;margin:20px 0 10px 0;">
          <div style="width: 4px;height: 13px;border-radius: 50px;background: #00A78E;margin-right: 4px;"></div>
          <div>其他设置</div>
        </div>
        <div style="display: flex;align-items: center;margin: 16px 0;">
          <div style="width: 120px;text-align: right;">性别：</div>
          <div>
            <el-radio-group v-model="sex" text-color="#00A78E">
              <el-radio :label="0">女</el-radio>
              <el-radio :label="1">男</el-radio>
            </el-radio-group>
          </div>
        </div>
        <div style="display: flex;align-items: center;margin: 16px 0;">
          <div style="width: 120px;text-align: right;">生日：</div>
          <div>
            <el-date-picker v-model="birthday" type="date" placeholder="选择日期" value-format="yyyy-MM-dd">
            </el-date-picker>
          </div>
        </div>
        <div style="display: flex;align-items: center;margin: 16px 0;">
          <div style="width: 120px;text-align: right;">讲师照片：</div>
          <img :src="teacherpicUri" alt="" style="width: 104px;height: 104px;" v-if="teacherpicUri">
          <input type="file" @change="uploadTeacher" id='file2' accept='image/*' ref='fileimgs' style="color:#fff"
            v-if="!teacherpicUri" />
          <el-link type="primary" :underline="false" @click="teacherpicUri = ''" v-if="teacherpicUri">删除</el-link>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelTeacher">取 消</el-button>
        <el-button type="primary" style="background: #00A78E;color: #fff;" @click="addTeacher">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="内容管理" :visible.sync="dialogVisibleContent" width="60%" class="dialog1" :close-on-click-modal="false">
      <div style="color: #ACB6B5;display: flex;justify-content: space-between;">
        <div style="display: flex;">
          <div>当前位置：</div>
          <div v-for="item in brandList" v-if="item.id == brandId">{{ item.name }}</div> -
          <div v-for="item in cateGoryList" v-if="item.record.id == recordId">{{ item.record.name }}</div> -
          <div>{{ courseName }}</div>
        </div>
        <div class="hand"
          style="color: #fff;background: #00A78E;width: 96px;height: 32px;line-height: 32px;text-align: center;border-radius: 5px;"
          @click="addContentNew">+添加内容</div>
      </div>
      <div style="display: flex;height: 90%;">
        <!-- <div
          style="padding: 8px 16px;box-shadow: 0px 4px 10px 0px rgba(62, 68, 77, 0.2);height: 100%;width: 144px;border-radius: 10px;">
          <div style="color: #ACB6B5;">课程包目录</div>
          <div style="margin: 16px 0;position: relative;padding: 10px 0;" v-for="(item, index) in tableData" :key="index">
            <div
              style="position: absolute;width: 5px;height: 24px;border-radius: 0px 27px 30px 0px;background: #00A78E;left: -16px;top: 8px;" v-if="courseId==item.record.id">
            </div>
            <div :class="courseId==item.record.id?'selectContent':''" >{{ item.record.name }}</div>
          </div>
          </div> -->
        <div style="width: 100%;">

          <el-table :data="tableData2"
            style="width: 95%;display: flex;flex-direction: column;align-items: flex-end;margin-top: 20px;"
            :header-cell-style="{ background: '#F7F7F7' }">
            <el-table-column prop="name" label="课程包名称">
            </el-table-column>
            <el-table-column prop="weight" label="权重">
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <div>
                  <el-link type="primary" :underline="false" @click="editContent(scope.row)">编辑</el-link>
                  <el-link type="primary" :underline="false" style="margin-left: 20px;"
                    @click="deleteContent(scope.row.id)">删除</el-link>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </el-dialog>

    <el-dialog title="添加内容" :visible.sync="dialogVisibleContentAdd" width="30%" :before-close="closeAddContent" :close-on-click-modal="false">
      <div style="overflow: auto;">
        <!-- <div
          style="display: flex;align-items: center;font-size: 14px;width: 100px;justify-content: flex-end;margin-bottom: 10px;">
          <div style="width: 4px;height: 13px;border-radius: 50px;background: #00A78E;margin-right: 4px;"></div>
          <div>基础设置</div>
          </div> -->
        <div style="display: flex;align-items: center;">
          <div style="width: 80px;text-align: right;">课程名称：</div>
          <el-input v-model="contentName" placeholder="请输入课程名称" style="width: 264px;"></el-input>
        </div>
        <div style="display: flex;align-items: center;margin: 20px 0;">
          <div style="width: 80px;text-align: right;">课程权重：</div>
          <el-input v-model="contentWeight" placeholder="请输入数字" style="width: 264px;"></el-input>
        </div>
        <div style="display: flex;align-items: center;">
          <div style="width: 80px;text-align: right;">视频fileId：</div>
          <el-input v-model="fileId" placeholder="视频fileId：" style="width: 264px;"></el-input>
        </div>
        <div style="display: flex;align-items: center;margin: 20px 0;">
          <div style="width: 80px;text-align: right;">视频url：</div>
          <el-input v-model="videoUri" placeholder="请输入视频链接" style="width: 264px;"></el-input>
        </div>
        <div style="display: flex;align-items: center;margin: 16px 0;">
          <div style="width: 80px;text-align: right;flex-shrink: 0;">视频上传：</div>
          <input type="file" id='filevideo' accept='video/mp4' ref='filevideo' style="color:#fff" @change="uploadvideo" />
          <video :src="videoUri" v-if="videoUri" class="avatar" controls="controls" style="width: 351px; height: 198px"
            id="videosrc">
            您的浏览器不支持视频播放
          </video>
          <el-progress type="circle" :percentage="percentage"
            style="width: 351px;height: 198px;justify-content: center;align-items: center;"
            v-if="!videoUri"></el-progress>
        </div>
        <div style="display: flex;margin: 20px 0;flex-direction: column;">
          <div style="width: 80px;text-align: right;">课件设置：</div>
          <quill-editor style="height:200px;width:100%;" v-model="msg2" ref="richAnalysis2" :options="optionsContent"
            @change="quillchange"></quill-editor>
          <input type="file" @change="uploadRichImgMethod2" accept='image/*' ref='uploadRichImg2' id="uploadRichImg2"
            style="display:none" />
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeAddContent">取 消</el-button>
        <el-button type="primary" style="background: #00A78E;color: #fff;" @click="confirmContent">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="提示" :visible.sync="dialogVisibleDell" width="30%">
      <span>请确认是否删除</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleDell = false">取 消</el-button>
        <el-button type="primary" @click="deleteC">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="试题管理" :visible.sync="dialogVisibleQuestion" width="60%" class="dialog1" :close-on-click-modal="false">
      <div style="color: #ACB6B5;">
        <div style="display: flex;">
          <div>当前位置：</div>
          <div v-for="item in brandList" v-if="item.id == brandId">{{ item.name }}</div> -
          <div v-for="item in cateGoryList" v-if="item.record.id == recordId">{{ item.record.name }}</div> -
          <div>{{ courseName }}</div>
        </div>
        <div style="display: flex;justify-content: space-between;align-items: center;">
          <div style="display: flex;" class="typeWrap">
            <div v-for="item in levelList" :class="sellevelId == item.id ? 'selectLevel' : ''"
              style="width: 90px;height: 32px;line-height: 32px;text-align: center;" @click="selectQues(item)">
              {{ item.name }}</div>
          </div>
          <div class="hand"
            style="color: #fff;background: #00A78E;width: 96px;height: 32px;line-height: 32px;text-align: center;border-radius: 5px;"
            @click="addQuestion">+添加试题</div>
        </div>
        <div style="display: flex;justify-content: space-between;align-items: center;">
          <div style="display: flex;" class="typeWrap">
            <div v-for="item in questionList" :class="questionId == item.id ? 'selectLevel' : ''"
              style="width: 90px;height: 32px;line-height: 32px;text-align: center;" @click="questionId = item.id">
              {{ item.name }}</div>
          </div>
          <div style="width: 221px;display: flex;">
            <el-input v-model="questionStr" placeholder="请输入搜索内容" class="cinput ac"
              @keyup.enter.native="getQuestionList"></el-input>
            <div style="display: flex;
                  height: 34px;
                  width: 34px;
                  align-items: center;
                  background: #f7f7f7;
                ">
              <img v-if="isshow" src="../../images/search2.png" alt="" @click="getQuestionList"
                style="background: #f7f7f7;width: 25px;height: 25px;" />
              <img v-if="isshow == false" src="../../images/search1.png" alt=""
                style="background: #f7f7f7;width: 25px;height: 25px;" />
            </div>
          </div>
        </div>
      </div>

      <div style="display: flex;height: 90%;">
        <div style="width: 100%;">
          <el-table :header-cell-style="{ background: '#F7F7F7' }" :data="tableData3"
            style="width: 100%; min-height: 500px" @selection-change="handleSelectionChange">
            <el-table-column type="selection" align='center' width="55" />
            <el-table-column type="index" width="50"> </el-table-column>
            <!-- <el-table-column prop="levelName" label="等级" align="center" width="220">
          </el-table-column> -->
            <el-table-column prop="content" align="left" label="题目" :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="content" align="left" label="层级" :show-overflow-tooltip="true" width="200">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.levelId == 1 ? 'lv1' : scope.row.levelId == 2 ? 'lv2' : 'lv3'
                  }}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="address" label="操作" align="center" width="240">
              <template slot-scope="scope">
                <div style="display: flex; justify-content: center">
                  <el-link type="primary" :underline="false" @click='questionDetails(scope.row)'>查看详情</el-link>

                  <el-link type="primary" :underline="false" @click='deleQuestion(scope.row)'
                    style="margin-left:31px">删除</el-link>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <div style="display: flex;align-items: center;justify-content: flex-end;
          ">
          <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="pageIndex" :page-sizes="[10]" :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper" :total="total">
          </el-pagination>
        </div>
      </span>
    </el-dialog>


  </div>
</template>

<script>
var COS = require('cos-js-sdk-v5');
var cos = new COS({
  getAuthorization: function (options, callback) {
    getCosCredit().then(result => {
      var data = result.data.data;
      var credentials = data && data.credentials;
      if (!data || !credentials) return console.error('credentials invalid');
      sessionStorage.setItem('bucket', data.bucket)
      callback({
        TmpSecretId: credentials.tmpSecretId,
        TmpSecretKey: credentials.tmpSecretKey,
        XCosSecurityToken: credentials.sessionToken,
        // 建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
        StartTime: data.startTime, // 时间戳，单位秒，如：1580000000
        ExpiredTime: data.expiredTime, // 时间戳，单位秒，如：1580000900
      });
    })
  }
});
const toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
  ['blockquote', 'code-block'],

  [{ 'header': 1 }, { 'header': 2 }],               // custom button values
  [{ 'list': 'ordered' }, { 'list': 'bullet' }],
  [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
  [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
  [{ 'direction': 'rtl' }],                         // text direction

  [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
  [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

  [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
  [{ 'font': [] }],
  [{ 'align': [] }],
  ['link', 'image', 'video'],
  ['clean']                                         // remove formatting button
]
import { getNowDate } from '../../utils/getNowDate'
import TcVod from 'vod-js-sdk-v6'
import uploadImg from '../../utils/uploadImg'
import uploadRIchImg from '../../utils/uploadRIchImg'
import uploadRichText from '../../utils/uploadRichText'
import { deleteVideoContent, audioResource, qssavedel, qssaveOrUpdate2, qssaveOrUpdate, qsList, dellmedia, contentEdit, courseEdit, contentList, courseDele, contentDelet, courseInfoList, levelList, getCosCredit, contentAdd, currentCourseList, courseDeleteAll, teacherList, teacherAdd, courseAdd, courseClassList, getVideoContents, getRichText, uploadSignature, questionList } from '../../apis/index'
export default {
  data() {
    return {
      audioBrandId:'',
      searchStr: '',//音频名称
      contentIdTrue: '',//编辑内容id
      courseIdTrue: '1',//默认的音频课程为1
      resourceUrl: '',//音频资源
      onlineTimeType: '1',//1上线即发布 2 自选时间
      onlineTime: '',//上线时间
      durationMillisecond: '',//视频时长
      tableData3: [],
      tagList: ['层级一', '层级二', '层级三'],
      tag: '',
      editQuestionId: '',//编辑试题的id
      checkList: [],
      radioLevel: '1',//添加试题的层级
      typeId: '1',//题目类型
      content: '',//题干
      radioAnsWer: '',//判断题选择
      audiobrandList:[
        // {
        //   id:0,
        //   name:'所有'
        // },
        {
          id:1,
          name:'嘉顿'
        },
        {
          id:2,
          name:'朵拉'
        },
        {
          id:4,
          name:'纯轻颜'
        },
      ],
      selectData: [
        {
          label: "1",
          name: "A",
          value: "",
        },
        {
          label: "2",
          name: "B",
          value: "",
        },
        {
          label: "3",
          name: "C",
          value: "",
        },
      ],
      dialogVisibleQuestion: false,//试题管理
      dialogVisibleQuestionAdd: false,//添加试题框
      questionStr: '',//搜索题目关键字
      levelList: [//层级列表
        {
          name: '全部',
          id: ''
        },
        {
          name: 'lv1',
          id: 1
        },
        {
          name: 'lv2',
          id: 2
        },
        {
          name: 'lv3',
          id: 3
        },
      ],
      levelId: '1',//层级id
      sellevelId: '',//筛选用的
      questionList: [//问题类型列表
        {
          name: '全部',
          id: ''
        },
        {
          name: '选择题',
          id: 1
        },
        {
          name: '判断题',
          id: 2
        }
      ],
      questionId: '',//问题类型id
      isshow: false,
      uploader: '',
      fileId: '',//视频标识
      coursePageName: '',//搜索输入的课程包名称
      courseName: '',//当前课程包名称
      priceMoney: '',
      pricePoint: '',
      contentId: '',//内容id
      contentName: '',//内容名称
      contentWeight: '',//内容权重
      percentage: 0,//视频上传进度
      videoUri: '',//视频uri    https://sihuankangyuan.cn/content/resource/newManage/video/44132e0c68b8f79975c84d66e270f73a1662426568160.mp4
      teacherMain: '',
      msg2: '',
      dialogVisibleDell: false,
      courseId: '',
      dropdownList: [
        // {
        //   name: '详情',
        //   value: 'a'
        // },
        {
          name: '编辑',
          value: 'b'
        },
        {
          name: '删除',
          value: 'c'
        }
      ],
      courseTitleInd: 0,
      configState: 0,
      configType: '',
      listTeacher: '',
      tableData: [],//课程包列表
      recordId: '',//右边标签标示id
      cateGoryList: [],
      homeItem: {
        picUri: '',
        isValidity: false,
        id: ''
      },
      experience: "",//经验
      point: '',//积分
      msg: '',
      configValue1: '',//付费设置
      configValue2: false,//是否需要考试
      weight: '',
      categoryId: '',//分类id
      listTeacher: [],//讲师列表
      teacherUserId: '',//讲师id
      birthday: '',//讲师生日
      des: '',//讲师简介
      name: '',
      name2: '',//讲师名称
      phone: '',//讲师手机
      teacherpicUri: '',//讲师照片
      sex: '',//讲师性别
      picUri: '',//课程包封面
      dialogVisibleTeacher: false,//添加导师弹窗
      tableData2: [],
      dialogVisible: false,
      dialogVisible2: false,
      dialogVisibleContent: false,//内容管理
      dialogVisibleContentAdd: false,//添加内容
      statusList: ['全部', '已发布', '待发布'],
      brandId: 1,
      brandList: [],
      levelLists: [],
      leveId: 1,
      total: 0,
      pageIndex: 1,
      pageSize: 10,
      onlineState: '',
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
      ],
      value: "",
      isshow: false,
      isshow2: true,
      isdisabled: true,
      currentPage: 1,
      radio: "4",
      input: "",
      options2: {
        theme: "snow",
        placeholder: "请输入内容",
        modules: {
          toolbar: {
            container: toolbarOptions,  // 工具栏
            handlers: {
              'image': function (value) {
                if (value) {
                  document.getElementById('uploadRichImg').click()
                } else {
                  this.quill.format('image', false);
                }
              }
            }
          }
        }
      },
      optionsContent: {
        theme: "snow",
        placeholder: "请输入内容",
        modules: {
          toolbar: {
            container: toolbarOptions,  // 工具栏
            handlers: {
              'image': function (value) {
                if (value) {
                  document.getElementById('uploadRichImg2').click()
                } else {
                  this.quill.format('image', false);
                }
              }
            }
          }
        }
      },
    };
  },

  computed: {
    filterOnlineTime: function () {
      let newData = getNowDate()
      return this.onlineTimeType == 1 ? newData : this.onlineTime
    }
  },
  filters:{
    timeLengthFilter(val){//音频视长过滤器
        if(!val) return
        let m=parseInt(val/60)<10?'0'+parseInt(val/60):parseInt(val/60)
        let s=parseInt(val%60)<10?'0'+parseInt(val%60):parseInt(val%60)
        return `${m}分${s}秒`
    },
    onlinTimeFilter(val){//发布时间过滤器
      if(!val) return
      let m=val.substring(5,7)
      let d=val.substring(8,10)
      let h=val.substring(11,13)
      let s=val.substring(14,16)
      return `${m}月${d}日  ${h}:${s}`
    },
  },
  watch: {
    searchStr(newData) {
      if (newData == '') {
        this.getVideoList()
      }
    },
  },
  created() {
    this.brandList = this.$store.state.brandList
    let categoryId = this.$route.query.id
    let brandId = this.$route.query.brandId
    this.recordId = this.categoryId = categoryId
    this.brandId = Number(brandId)
    // this.getcontentList()
    // this.getTeacherList()
    // this.getCateGorylist()
    this.getVideoList()
    // this.getUploadSignature()
  },
  //     this.leave().then(res => {
  //       this.list()
  //     })
  //   },
  //   beforeRouteLeave(to, from, next) {
  //   if(to.fullPath=='/videoAdd' || to.fullPath=='/contentAdd'){//添加页，就不缓存
  //     this.$route.meta.keepAlive=false
  //     setTimeout(() => {
  //       next()
  //     }, 100);
  //   }else{//详情页缓存
  //   console.log('我是详情缓存')
  //    this.$route.meta.keepAlive=true
  //     setTimeout(() => {
  //       next()
  //     }, 100);
  //   }
  //   // 导航离开该组件的对应路由时调用
  //   // 可以访问组件实例 `this`
  // },
  methods: {
    getAudio(e){
      console.log(e)
      // let total=e.reduce((prev,current)=>prev+current,0)
      this.audioBrandId=e
    },
    filterOnline(value) {
      let notTime = new Date().getTime()//当前时间
      let online = new Date(value).getTime()//上线时间
      if (notTime >= online) {
        return true
      } else {
        return false
      }
    },
    dele(row) {//删除音频
      const params = {
        contentId: row.id,
        courseId: row.courseId
      }
      this.$confirm('此操作将永久删除该项, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteVideoContent(params).then(res => {
          if (res.data.code == 200) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.getVideoList()
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            });
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    async countAudioTime(url) {//计算音频时长
      const audio = new Audio()
      audio.src = url
      while (isNaN(audio.duration) || audio.duration === Infinity) {
        // 延迟一会 不然网页都卡死
        await new Promise(resolve => setTimeout(resolve, 200));
        // 设置随机播放时间，模拟调进度条
        audio.currentTime = 10000000 * Math.random();
      }
      this.timeLength = parseInt(audio.duration)
      console.log('音频的总时长:', parseInt(audio.duration))
    },
    selectQues(item) {
      this.sellevelId = item.id
      this.getQuestionList()
    },
    deleQuestion(row) {
      const params = {
        id: row.id,
        courseId: this.courseId
      }
      this.$confirm('此操作将永久删除该项, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        qssavedel(params).then(res => {
          if (res.data.code == 200) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.getQuestionList()
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            });
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    sel(val, index) {
      this.selectData[index].checked = val
      let obj = {
        checked: val,
        label: this.selectData[index].label,
        name: this.selectData[index].name,
        value: this.selectData[index].value,
      }
      this.$set(this.selectData, index, obj)
    },
    questionDetails(details) {//查看试题详情
      this.dialogVisibleQuestionAdd = true
      console.log(details)
      this.courseId = details.courseId
      if (details.typeId == 1) {//选择题
        this.typeId = String(details.typeId)
        // this.levelId=details.levelId
        this.content = details.content
        let answer = details.answer
        let arr = answer.split('&&&')
        let obj = arr.map((item, index) => {
          return {
            label: index,
            name: item.slice(0, 1),
            value: item.slice(1)
          }
        })
        this.selectData = obj
        let rightKey = details.rightKey
        let arr2 = rightKey.split('')
        let selectData = this.selectData
        arr2.map((item, index) => {
          selectData.forEach((item2, index2) => {
            if (index == index2 && item == 1) {
              item2.checked = true
            }
          })
        })
        this.editQuestionId = details.id
        this.selectData = selectData
        this.checkList = selectData.filter(item => item.checked).map(item => item.label)
        console.log(selectData)
      } else if (details.typeId == 2) {//判断题
        this.typeId = String(details.typeId)
        // this.levelId=details.levelId
        this.content = details.content

        this.selectData = this.selectData = [
          {
            label: "1",
            name: "正确",
          },
          {
            label: "2",
            name: "错误",
          },
        ];
        let selectData = this.selectData
        this.editQuestionId = details.id
        let rightKey = details.rightKey
        let arr2 = rightKey.split('')
        arr2.map((item, index) => {
          selectData.forEach((item2, index2) => {
            if (index == index2 && item == 1) {
              this.radioAnsWer = item2.label
            }
          })
        })
      }

    },

    deleIndex(ind) {
      this.selectData.splice(ind, 1);
    },
    handleCurrentChange(val) {
      this.pageIndex = val
      this.getVideoList()
    },
    openQuestionList(row) {//打开试题列表
      this.dialogVisibleQuestion = true
      this.courseId = row.record.id
      this.courseName = row.record.name
      this.getQuestionList()
    },
    async getQuestionList() {//获取试题列表
      const params = {
        questionTypeId: this.questionId,
        searchStr: this.questionStr,
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        levelId: this.sellevelId,
        brandId: this.brandId,
        courseId: this.courseId
      }
      const { data } = await qsList(params)
      if (data.code == 200) {
        this.tableData3 = data.data.records
        this.total = Number(data.data.total)
      }
    },
    async getUploadSignature() {//获取云点播上传凭证
      const { data } = await uploadSignature()
      if (data.code == 200) {
        sessionStorage.setItem('getUploadSignature', data.data.getUploadSignature)
        return data.data.getUploadSignature
      }
      console.log(data.data.getUploadSignature)
    },
    closeAddContent() {
      console.log('我是取消')
      this.dialogVisibleContentAdd = false
      if (this.percentage && this.percentage !== 100) {
        this.uploader.cancel()
        this.$message.error('已取消上传!')
        this.$refs["filevideo"].value = ''
        this.fileId = ''
        this.videoUri = ''
        return
      } else if (this.fileId && !this.contentId) {//上传完视频点击了取消，删除云点播里面的资源
        dellmedia({ fileId: this.fileId }).then(res => {
          if (res.data.code == 200) {
            this.fileId = ''
          }
        })
      } else {
        // this.uploader.cancel()
        this.$refs["filevideo"].value = ''
      }

    },
    addteac() {
      this.dialogVisibleTeacher = true
    },
    uploadTeacher() {//上传讲师封面
      if (this.$refs['fileimgs'].files[0]) {
        let selectFileobj = this.$refs['fileimgs'].files[0]
        uploadImg(selectFileobj, (res) => {
          console.log(res)
          this.teacherpicUri = res
        })
      }
    },
    cancelCourse() {//取消添加课程包
      this.dialogVisible = false
      this.configValue1 = ''
    },
    uploadvideo(e) {
      let that = this
      if (this.percentage && this.percentage !== 100) {
        this.$message.error('有上传任务!')
        return
      }
      //上传视频
      if (this.$refs["filevideo"].files[0]) {
        // let filename = this.$refs["filevideo"].files[0].name;
        let selectFileobj = this.$refs["filevideo"].files[0];

        const url = URL.createObjectURL(selectFileobj);
        const filelement = new Audio(url);
        filelement.addEventListener("loadedmetadata", function () {
          this.durationMillisecond = filelement.duration * 1000 //获取视频时长
          console.log(this.durationMillisecond)
          sessionStorage.setItem('durationMillisecond', this.durationMillisecond)
          console.log(selectFileobj);
          this.videoUri = ''
          that.uploadYdb(selectFileobj)//腾讯云云点播上传视频
        })


        // console.log(selectFileobj);
        // this.videoUri = ''
        // this.uploadYdb(selectFileobj)//腾讯云云点播上传视频


        // this.uploadvideo2(selectFileobj)//腾讯云对象存储上传视频
      }
    },
    uploadYdb(selectFileobj) {//腾讯云云点播上传视频
      let that = this
      let getVideoSignature = () => {
        return that.getUploadSignature()
      }
      const tcVod = new that.$TcVod({
        getSignature: getVideoSignature // 前文中所述的获取上传签名的函数
      })
      const uploader = tcVod.upload({
        mediaFile: selectFileobj, // 媒体文件（视频或音频或图片），类型为 File
      })
      that.uploader = uploader
      sessionStorage.setItem('selectFileobjData', selectFileobj)
      uploader.on('media_progress', function (info) {
        console.log(info.percent) // 进度
        that.percentage = parseInt(info.percent * 100);
      })
      uploader.done().then(function (res) {
        console.log(res)
        let fileId = res.fileId
        that.videoUri = res.video.url
        that.fileId = fileId
        sessionStorage.setItem('videoUri', res.video.url)
        that.$refs["filevideo"].value = ''
        setTimeout(() => {
          that.$message({
            message: `上传成功，视频正在转码请稍后再播放`,
            type: 'warning',
          });
        }, 1000)
        // deal with doneResult
      }).catch(function (err) {
        // deal with error
      })
    },

    uploadvideo2(selectFileobj) {//腾讯云对象存储上传视频
      let that = this;
      cos.options.getAuthorization("", function (obj) {
        let name = selectFileobj.name.replace(/MP4/g, "mp4");

        let filename = 'content/resource/newManage/video/' +
          name.slice(0, name.length - 4) +
          new Date().getTime() +
          name.slice(-4);
        console.log(filename)
        cos.sliceUploadFile(
          {
            Bucket: sessionStorage.getItem("bucket") /* 必须 */,
            Region: "ap-nanjing" /* 存储桶所在地域，必须字段 */,
            Key: filename /* 必须 */,
            Body: selectFileobj /* 必须 */,
            onTaskReady: function (taskId) {
              /* 非必须 */
              console.log(taskId);
              that.taskId = taskId
            },
            onHashProgress: function (progressData) {
              /* 非必须 */
              console.log(JSON.stringify(progressData));
            },
            onProgress: function (progressData) {
              /* 非必须 */
              console.log(JSON.stringify(progressData));
              that.percentage = parseInt(progressData.percent * 100);
            },
          },
          function (err, data) {
            console.log(err || data);
            if (data) {
              let Location = data.Location.replace(/content-1308336073.cos.ap-nanjing.myqcloud.com/g, 'https://sihuankangyuan.cn')
              that.videoUri = Location
              sessionStorage.setItem('videoUri', Location)
              setTimeout(() => {
                that.$message({
                  message: `上传成功，视频正在转码请稍后再播放`,
                  type: 'warning',
                });
              }, 1000)
            }
          }
        );
      });
    },
    async getCateGorylist() {//获取类列表
      const params = {
        brandId: this.brandId
      }
      const { data } = await courseClassList(params)
      if (data.code == 200) {
        this.cateGoryList = data.data
      }
    },
    courseTitle(index) {//点击切换课程类型
      if (index == 0) {
        this.courseTitleInd = 0,
          this.onlineState = ''
      } else if (index == 1) {
        this.courseTitleInd = 1,
          this.configState = 2
        this.onlineState = 1
      } else if (index == 2) {
        this.courseTitleInd = 2,
          this.configState = 4
        this.onlineState = 0
      }
      this.getVideoList()
    },
    async getVideoList() {//获取音频列表
      const params = {
        courseId: this.courseIdTrue,
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        onlineState: this.onlineState,
        searchStr: this.searchStr
      }
      const { data } = await getVideoContents(params)
      if (data.code == 200) {
        this.tableData = data.data.list
        this.total=data.data.totalCount
      }
    },
    async getcontentList() {//获取内容列表
      const params = {
        brandId: this.brandId,
        courseId: this.courseId,
      }
      const { data } = await contentList(params)
      if (data.code == 200) {
        this.tableData2 = data.data
      }
    },
    uploadRichImgMethod(e) {//上传图片至富文本
      if (this.$refs['uploadRichImg'].files[0]) {
        let selectFileobj = this.$refs['uploadRichImg'].files[0]
        let quill = this.$refs.richAnalysis.quill
        uploadRIchImg(selectFileobj, quill)
      }
    },
    uploadRichImgMethod2(e) {//内容部分上传图片至富文本
      if (this.$refs['uploadRichImg2'].files[0]) {
        let selectFileobj = this.$refs['uploadRichImg2'].files[0]
        let quill = this.$refs.richAnalysis2.quill
        uploadRIchImg(selectFileobj, quill)
      }
    },
    async getTeacherList() {//获取讲师列表
      const { data } = await teacherList()
      if (data.code == 200) {
        this.listTeacher = data.data
      }
    },
    cancelTeacher() {//取消导师按钮
      this.birthday = ''
      this.des = ''
      this.name2 = ''
      this.phone = ''
      this.teacherpicUri = ''
      this.sex = ''
      this.dialogVisibleTeacher = false
    },
    async addTeacher() {//添加导师
      const params = {
        birthday: this.birthday,
        des: this.des,
        name: this.name2,
        phone: this.phone,
        picUri: this.teacherpicUri,
        sex: this.sex
      }
      const { data } = await teacherAdd(params)
      if (data.code == 200) {
        this.$message({
          type: 'success',
          message: '添加成功!'
        });
        this.dialogVisibleTeacher = false
        this.birthday = ''
        this.des = ''
        this.name2 = ''
        this.phone = ''
        this.teacherpicUri = ''
        this.sex = ''
        this.getTeacherList()
      }
    },
    handleCloseTeacher() {//关闭讲师添加弹窗
      this.birthday = ''
      this.des = ''
      this.name2 = ''
      this.phone = ''
      this.teacherpicUri = ''
      this.sex = ''
      this.dialogVisibleTeacher = false
    },
    addCourseTag() {//添加音频按钮
      this.dialogVisible = true
      this.des = ''
      this.name = ''
      this.picUri = ''
      this.teacherUserId = ''
      this.weight = ''
      this.point = ''
      this.experience = ''
      this.homeItem = {}
      this.configValue = ''
      this.configValue1 = ''
      this.configValue2 = false
      this.desUri = ''
      this.msg = ''
      this.courseId = ''
      this.priceMoney = '',
        this.pricePoint = ''
      this.tag = ''
      this.resourceUrl = ''
      this.onlineTime = ''
      this.msg = ''
      this.contentIdTrue = ''

    },
    addQuestion() {//添加试题
      this.dialogVisibleQuestionAdd = true
      this.editQuestionId = ''
      this.leveId = '1'
      this.typeId = '1'
      this.content = ''
      this.selectData = [
        {
          label: "1",
          name: "A",
          value: "",
        },
        {
          label: "2",
          name: "B",
          value: "",
        },
        {
          label: "3",
          name: "C",
          value: "",
        },
      ];
    },
    closeAddQuestion() {//关闭试题
      this.dialogVisibleQuestionAdd = false
    },
    editContent(row) {//编辑内容
      this.dialogVisibleContentAdd = true
      this.contentId = row.id
      this.contentName = row.name
      this.contentWeight = row.weight
      this.videoUri = row.videoUri
      this.fileId = row.fileId
      getRichText(row.resourceUri).then(res => {
        this.msg2 = res.data
      })
    },
    addContentNew() {//添加内容
      this.dialogVisibleContentAdd = true
      this.contentId = ''
      this.contentName = ''
      this.contentWeight = ''
      this.videoUri = ''
      this.msg2 = ''
      this.percentage = 0

    },
    confirmContent() {
      if (!this.contentId) {//保存内容
        uploadRichText(this.msg2, (desUri) => {
          const params = {
            name: this.contentName,
            weight: this.contentWeight,
            videoUri: this.videoUri,
            resourceUri: desUri,
            brandId: this.brandId,
            courseId: this.courseId,
            fileId: this.fileId,
            // durationMillisecond:this.durationMillisecond
            durationMillisecond: Number(sessionStorage.getItem('durationMillisecond'))
          }
          contentAdd(params).then(res => {
            if (res.data.code == 200) {
              this.$message.success('添加成功')
              this.getcontentList()
              this.dialogVisibleContentAdd = false
              this.fileId = ''
              this.videoUri = ''
              sessionStorage.setItem('durationMillisecond', '')
            }
          })
          console.log(params)
        })
      } else {//更新
        uploadRichText(this.msg2, (desUri) => {
          const params = {
            name: this.contentName,
            weight: this.contentWeight,
            videoUri: this.videoUri,
            resourceUri: desUri,
            brandId: this.brandId,
            courseId: this.courseId,
            id: this.contentId,
            fileId: this.fileId
          }
          contentEdit(params).then(res => {
            if (res.data.code == 200) {
              this.$message.success('添加成功')
              this.getcontentList()
              this.dialogVisibleContentAdd = false
              this.fileId = ''
              this.videoUri = ''
            }
          })
          console.log(params)
        })
      }

    },
    deleteContent(id) {//删除内容
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const params = {
          brandId: this.brandId,
          contentId: id
        }
        contentDelet(params).then(res => {
          if (res.data.code == 200) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.getcontentList()
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    edit(row) {//编辑音频
      console.log(row.brandId)
      let arr=[]
      if((row.brandId&1)==1){
        arr.push(1)
      }
      if((row.brandId&2)==2){
        arr.push(2)
      }
      if((row.brandId&4)==4){
        arr.push(4)
      }
      console.log(arr)
      this.dialogVisible = true
      this.courseId = row.courseIdTrue,
        this.name = row.name
      this.weight = row.weight
      this.onlineTime = row.onlineTime
      this.resourceUrl = row.resourceUrl
      this.timeLength = row.timeLength
      this.contentIdTrue = row.id
      this.onlineTimeType = '2'
      this.audioBrandId=arr
      if (row.richTextUrl) {
        getRichText('https://content-1308336073.cos.ap-nanjing.myqcloud.com/'+row.richTextUrl).then(res => {
          this.msg = res.data
        })
      }

    },
    saveAudio() {//保存音频
      // audioResource
      const params = {
        id: this.contentIdTrue ? this.contentIdTrue : '',
        courseId: this.courseIdTrue,
        name: this.name,
        weight: this.weight,
        onlineTime: this.filterOnlineTime,
        resourceUrl: this.resourceUrl,
        timeLength: this.timeLength,
        brandId:this.audioBrandId.reduce((prev,current)=>prev+current,0)
      }
      // console.log(params)
      // return
      if (this.contentIdTrue) {//编辑
        this.saveAudioMethod(params, 'put')
      } else {//保存
        this.saveAudioMethod(params, 'post')
      }

    },
    saveAudioMethod(paramsData, method) {
      uploadRichText(this.msg, (desUri) => {
        paramsData.richTextUrl = desUri
        audioResource(paramsData, method).then(res => {
          if (res.data.code == 200) {
            this.dialogVisible = false
            this.getVideoList()
            this.$message.success(res.data.msg)
          } else {
            this.$message.error(res.data.msg)
          }
        })
      })

    },
    saveCourse() {//保存课程包
      if (this.courseId) {//更新
        uploadRichText(this.msg, (desUri) => {
          const params = {
            brandId: this.brandId,
            categoryId: this.categoryId,
            des: this.des,
            name: this.name,
            picUri: this.picUri,
            teacherUserId: this.teacherUserId,
            weight: this.weight,
            point: this.point,
            experience: this.experience,
            id: this.courseId,
            homeItem: {
              picUri: this.homeItem.picUri,
              isValidity: this.homeItem.isValidity ? 1 : 0,
              id: this.homeItem.id
            },
            configValue: (this.configValue2 ? 1 : 0) + this.configValue1,
            desUri,
            priceMoney: Number(this.priceMoney) * 100,
            pricePoint: Number(this.pricePoint),
            tag: this.tag
          }
          courseEdit(params).then(res => {
            if (res.data.code == 200) {
              this.$message.success('更新成功')
              this.getVideoList()
              this.dialogVisible = false
              this.configValue1 = ''
            }
          })
          console.log(params)
        })
      } else {//添加
        if (this.msg) {

        } else {

        }
        // uploadRichText(this.msg, (desUri) => {
        //   const params = {
        //     brandId: this.brandId,
        //     categoryId: this.categoryId,
        //     des: this.des,
        //     name: this.name,
        //     picUri: this.picUri,
        //     teacherUserId: this.teacherUserId,
        //     weight: this.weight,
        //     point: this.point,
        //     experience: this.experience,
        //     homeItem: {
        //       picUri: this.homeItem.picUri,
        //       isValidity: this.homeItem.isValidity ? 1 : 0,
        //       id: this.homeItem.id
        //     },
        //     configValue: (this.configValue2 ? 1 : 0) + this.configValue1,
        //     desUri,
        //     priceMoney: Number(this.priceMoney) * 100,
        //     pricePoint: Number(this.pricePoint),
        //     tag: this.tag
        //   }
        //   courseAdd(params).then(res => {
        //     if (res.data.code == 200) {
        //       this.$message.success('添加成功')
        //       this.getVideoList()
        //       this.dialogVisible = false
        //       this.configValue1 = ''
        //     }
        //   })
        //   console.log(params)
        // })
      }

    },
    handleCommand(command) {
      console.log(command)
      if (command.type == 'a') {//详情

      } else if (command.type == 'b') {//编辑

        this.dialogVisible = true
        let row = command.row
        console.log(row.record.configValue)

        this.categoryId = row.record.categoryId
        this.des = row.record.des
        this.name = row.record.name
        this.picUri = row.record.picUri
        this.teacherUserId = row.record.teacherUserId
        this.weight = row.record.weight
        this.point = row.record.point
        this.experience = row.record.experience
        this.priceMoney = row.record.priceMoney / 100
        this.pricePoint = row.record.pricePoint
        this.courseId = row.record.id
        this.tag = row.record.tag
        this.homeItem = row.homeItem ? {
          picUri: row.homeItem.picUri,
          isValidity: row.homeItem.isValidity == 1 ? true : false,
          id: row.homeItem.id
        } : {
          picUri: '',
          isValidity: false,
          id: ''
        }
        // this.homeItem = {
        //   picUri: row.homeItem.picUri,
        //   isValidity: row.homeItem.isValidity == 1 ? true : false,
        //   id: row.homeItem.id
        // }
        if ((row.record.configValue & 2) == 2) {
          this.configValue1 = 2
        } else if ((row.record.configValue & 4) == 4) {
          this.configValue1 = 4
        } else {
          this.configValue1 = 0
        }
        if ((row.record.configValue & 1) == 1) {
          this.configValue2 = true
        } else {
          this.configValue2 = false
        }

        getRichText(row.record.desUri).then(res => {
          this.msg = res.data
        })
      } else {//删除
        this.dialogVisibleDell = true
        this.courseId = command.row.record.id
      }
    },
    deleteC() {//删除课程
      const params = {
        brandId: this.brandId,
        id: this.courseId
      }
      courseDele(params).then(res => {
        if (res.data.code == 200) {
          this.$message.success('删除成功')
          this.getVideoList()
          this.dialogVisibleDell = false
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    composeValue(item, row) {
      return {
        type: item,
        row: row
      }
    },
    changeTitle(id) {//点击右边标签
      this.categoryId = this.recordId = id
      this.getVideoList()
    },
    upload(selectFileobj) {
      let that = this;
      cos.options.getAuthorization("", function (obj) {
        let name = selectFileobj.name.replace(/MP4/g, "mp4");
        if (that.levelId == '') {
          that.$message.error('请先选择等级')
          document.getElementById('file').value = ''
          return
        }
        let filename = 'content/resource/LV' + that.levelId + '/' + 'video/' +
          name.slice(0, name.length - 4) +
          new Date().getTime() +
          name.slice(-4);
        console.log(filename)
        cos.sliceUploadFile(
          {
            Bucket: sessionStorage.getItem("bucket") /* 必须 */,
            Region: "ap-nanjing" /* 存储桶所在地域，必须字段 */,
            Key: filename /* 必须 */,
            Body: selectFileobj /* 必须 */,
            onTaskReady: function (taskId) {
              /* 非必须 */
              console.log(taskId);
              that.taskId = taskId
            },
            onHashProgress: function (progressData) {
              /* 非必须 */
              console.log(JSON.stringify(progressData));
            },
            onProgress: function (progressData) {
              /* 非必须 */
              console.log(JSON.stringify(progressData));
              that.percentage = parseInt(progressData.percent * 100);
            },
          },
          function (err, data) {
            console.log(err || data);
            if (data) {
              let Location = data.Location.replace(/content-1308336073.cos.ap-nanjing.myqcloud.com/g, 'https://sihuankangyuan.cn')
              // that.videoUri = "https://" + data.Location;
              that.videoUri = Location

              sessionStorage.setItem('videoUri', Location)

              setTimeout(() => {
                //   var videosrc = document.getElementById('videosrc');
                // that.duration=parseInt(videosrc.duration)
                // console.log(parseInt(videosrc.duration))
                that.$message({
                  message: `上传成功，视频正在转码请稍后再播放`,
                  type: 'warning',
                });
              }, 1000)
            }
          }
        );
      });
    },
    quillchange(delta, oldDelta, source) {
      console.log(delta, oldDelta, source)
    },

    uploadimgpicUri(e) {//上传音频
      let selectFileobj = this.$refs['fileimgCourse'].files[0]
      if (selectFileobj) {
        uploadImg(selectFileobj, (res) => {
          console.log(res)
          this.resourceUrl = res
          this.countAudioTime(res)
        })
      }
    },
    uploadimgpic(e) {//上传推荐封面
      if (this.$refs['fileimgPic'].files[0]) {
        let selectFileobj = this.$refs['fileimgPic'].files[0]
        uploadImg(selectFileobj, (res) => {
          this.homeItem = {
            picUri: res,
            isValidity: true,
            id: this.homeItem.id
          }
        })
      }
    },
    // upload2(selectFileobj) {
    //   let that = this
    //   let name = selectFileobj.name
    //   let filename = 'content/resource/newManage/image/' +
    //     name.slice(0, name.length - 4) +
    //     new Date().getTime() +
    //     name.slice(-4);
    //   console.log(filename)
    //   cos.options.getAuthorization('', function (obj) {
    //     cos.putObject({
    //       Bucket: sessionStorage.getItem('bucket'), /* 必须 */
    //       Region: 'ap-nanjing',     /* 存储桶所在地域，必须字段 */
    //       Key: filename,              /* 必须 */
    //       StorageClass: 'STANDARD',
    //       Body: selectFileobj, // 上传文件对象
    //       onProgress: function (progressData) {
    //         // console.log(JSON.stringify(progressData));
    //         console.log(progressData)
    //       }
    //     }, function (err, data) {
    //       console.log(err || data);
    //       if (data) {
    //         // let url='https://'+data.Location
    //         let picUri = data.Location.replace(/content-1308336073.cos.ap-nanjing.myqcloud.com/g, 'https://sihuankangyuan.cn')
    //         that.picUri = picUri
    //       }
    //     });
    //   })
    // },
    async getList() {
      const params = {
        brandId: this.brandId,
        parentId: this.id
      }
      const { data } = await currentCourseList(params)
      if (data.code == 200) {
        this.tableData = data.data
        console.log(data.data)
      }
    },

    goback() {
      this.$router.go(-1)
    },
    async leave() {
      //获取等级列表
      const { data } = await levelList({ roleId: 3 });
      if (data.code == 200) {
        this.levelLists = data.data;
      } else {
        this.$message.error(data.msg);
      }
    },
    lookvideo(row) {
      this.$router.push({ path: '/courseAdd', query: row })
    },
    lookcontent2(row) {
      this.$router.push({ path: '/courseContent', query: row })
    },
    addtext(row) {
      this.$router.push({ path: '/courseContent', query: { id: row.id, brandId: this.brandId } })
    },
    addtext2(row) {
      this.$router.push({ path: '/coursePageList3', query: { id: row.id, brandId: this.brandId } })
    },
    addtext4(row) {
      this.$router.push({ path: '/exercises', query: { id: row.record.id, brandId: this.brandId } })
    },
    lookcontent(row) {
      this.$router.push({ path: '/contentAdd', query: row })
    },
    async list() {//课程包列表
      const params = {
        brandId: this.brandId,
        levelId: this.leveId,
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        // contentType:this.radio,
        searchStr: this.input
      }
      const { data } = await courseInfoList(params)
      if (data.code == 200) {
        this.tableData = data.data.records,
          this.total = Number(data.data.total)
      }
      console.log(data)
    },
    details(row) {
      // this.$router.push({ path: '/courseChildAdd3', query: { row: JSON.stringify(row), brandId: this.brandId } })
      this.dialogVisibleContent = true
      this.courseId = row.record.id
      this.courseName = row.record.name
      this.getcontentList()
    },
    details2(row) {
      this.$router.push({ path: '/courseParentAdd3', query: { row: JSON.stringify(row), brandId: this.brandId } })
    },
    lookcontent3(row) {
      this.$router.push({ path: '/courseChildAdd2', query: { id: row.id, brandId: this.brandId } })
    },
    lookcontent4(row) {
      this.$router.push({ path: '/coursePageList2', query: { id: row.id, brandId: this.brandId } })
    },
    addcontent() {
      this.$router.push({ path: '/courseParentAdd', query: { id: this.id, brandId: this.brandId } })
      //   if(this.radio=='3'){
      //     this.$router.push({path:'/contentAdd'})
      //   }else{
      //     this.$router.push({path:'/videoAdd'})
      //   }
    },
    addcontent2() {
      this.$router.push({ path: '/courseChildAdd', query: { id: this.id, brandId: this.brandId } })
      //   if(this.radio=='3'){
      //     this.$router.push({path:'/contentAdd'})
      //   }else{
      //     this.$router.push({path:'/videoAdd'})
      //   }
    },
    search() {
      console.log("我是搜索");
    },
    handleSelectionChange(val) {//选中
      console.log(val)
      if (val.length > 0) {
        this.isdisabled = false
      } else {
        this.isdisabled = true
      }
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize = val
      this.getVideoList()
    },
    // handleCurrentChange(val) {
    //   console.log(`当前页: ${val}`);
    //   this.pageIndex = val
    //   this.list()
    // },

    deleall() {
      this.$confirm('此操作将永久删除选中项, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    }
  },
};
</script>

<style lang='less' scoped>
/deep/ .dialog1 .el-dialog__body {
  height: 85%;
  overflow: auto;
  padding: 0 20px;
}

/deep/ .dialog1 .el-dialog {
  margin: 0 !important;
  float: right;
  height: 100%;
}

.titleLayout {
  display: flex;
  border-radius: 8px 8px 0 0;
  background: #C6E7E2;
  height: 34px;
  line-height: 34px;
}

.currentTitle {
  background: #fff;
  border-radius: 8px 8px 0 0;
  height: 34px;
  line-height: 34px;
}

.main {
  // min-height: 800px;
  // background: #fff;
  margin: 19px 0;
  padding: 23px;
  border-radius: 14px;
  box-sizing: border-box;
  display: flex;
    flex: 1;
}

// .main>div:first-child {
//   font-size: 26px;
//   // font-weight: bold;
// }

// .main>div:nth-child(2) {
//   display: flex;
//   justify-content: space-between;
//   height: 34px;
//   margin: 18px 0;

//   div {
//     display: flex;

//     img {
//       width: 25px;
//       height: 25px;
//     }
//   }
// }
.changeSel {
  padding: 8px 22px;
  background: #2FAFA1;
  border-radius: 50px;
  color: #fff;
}

/deep/.el-radio-button__inner {
  height: 34px;
  line-height: 10px;
}

.cinput /deep/.el-input__inner {
  height: 34px;
  line-height: 10px;
}

/deep/.el-button--primary {
  height: 34px;
  line-height: 10px;
}

/deep/.el-table::before {
  height: 0;
}

.ac /deep/.el-input__inner {
  border: none;
  background: #f7f7f7;
  // margin-right: 11px;
}

.defaultcolor {
  color: #3C4645;
}

.red {
  color: #ff1b0b;
}

/deep/.el-radio__input.is-checked+.el-radio__label {
  color: #00A78E;
}

/deep/.el-radio__input.is-checked .el-radio__inner {
  border-color: #00A78E;
  background: #00A78E
}

.selectContent {
  color: #00A78E;
}

.cstatus1 {
  color: #FAAD14;
}

.cstatus2 {
  color: #54C97F;
}

.cstatus3 {
  color: #ACB6B5;
}

.hand:hover {
  cursor: pointer;
}

.typeWrap {
  margin: 10px 0;
}

.typeWrap:hover {
  cursor: pointer;
}

.typeWrap>div {
  border: 1px solid #CDD3D2;
  border-right: none;
}

.typeWrap>div:last-child {
  border: 1px solid #CDD3D2;
  border-radius: 0 8px 8px 0;
}

.typeWrap>div:first-child {
  border: 1px solid #CDD3D2;
  border-radius: 8px 0 0 8px;
}

.typeWrap>div.selectLevel {
  border: 1px solid #00A78E;
  color: #00A78E;
}

.line {
  width: 4px;
  height: 13px;
  background: #00A78E;
  border-radius: 50px;
  margin-right: 5px;
}
</style>
