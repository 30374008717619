<template>
  <div>
    <div>
      <el-row :gutter="20" style="height: 50px;margin-top: 20px;font-size: 24px">
        <el-col :span="24">
          <el-card class="box-card">
            <div slot="header" class="clearfix" style="display: flex;flex-direction: column">
              <div>
                <p >
                  <span style="font-size: 20px">筛选：</span>
                  <span>
                    <el-date-picker
                        v-model="dateList" @change="clickDatePicker"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始时间"
                        end-placeholder="结束时间"
                        value-format="yyyy-MM-dd">
                    </el-date-picker>
                  </span>
                  <span style="margin-left: 30px">
                     <el-radio-group v-model="radio"  @change="clickDate">
                        <el-radio :label="1">今天</el-radio>
                        <el-radio :label="2">本周</el-radio>
                        <el-radio :label="3">本月</el-radio>
                        <el-radio :label="4">本年</el-radio>
                    </el-radio-group>
                  </span>
                </p>
              </div>
              <div style="margin-top: 20px">
                <p>
                  <span  style="font-size: 20px">统计维度：</span>
                  <span>
                    <el-radio-group v-model="statisticsVal"  @change="clickRate">
                        <el-radio :label="1">数量</el-radio>
                        <el-radio :label="2">点击率</el-radio>
                    </el-radio-group>
                  </span>

                  <span style="margin-left: 30px">
                    <el-button type="primary" @click="query">筛选</el-button>
                    <el-button @click="reset">重置</el-button>
                  </span>
              </p>
              </div>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </div>
    <div>
      <el-row :gutter="20" style="margin-top: 20px">
        <el-col :span="16">
          <el-row :gutter="20">
            <el-col :span="6">
              <el-card class="box-card">
                <div slot="header" class="clearfix" >
                  <div><p><span class="card-title">我的学习</span><span></span></p></div>
                  <div><p><span class="num">{{ myStudy }}</span><span v-show="isRate">%</span></p></div>
                </div>
              </el-card>
            </el-col>
            <el-col :span="6">
              <el-card class="box-card">
                <div slot="header" class="clearfix">
                  <div><p><span class="card-title">学习日历</span><span></span></p></div>
                  <div><p><span class="num">{{ studyCalendar }}</span><span v-show="isRate">%</span></p></div>
                </div>
              </el-card>
            </el-col>
            <el-col :span="6">
              <el-card class="box-card">
                <div slot="header" class="clearfix">
                  <div><p><span class="card-title">正在学</span><span></span></p></div>
                  <div><p><span class="num">{{ studying }}</span><span v-show="isRate">%</span></p></div>
                </div>
              </el-card>
            </el-col>
            <el-col :span="6">
              <el-card class="box-card">
                <div slot="header" class="clearfix">
                  <div><p><span class="card-title">个人训练</span><span></span></p></div>
                  <div><p><span class="num">{{ personalTraining }}</span><span v-show="isRate">%</span></p></div>
                </div>
              </el-card>
            </el-col>
          </el-row>
          <el-row :gutter="20" style="margin-top: 30px">
            <el-col :span="6">
              <el-card class="box-card">
                <div slot="header" class="clearfix" >
                  <div><p><span class="card-title">去PK</span><span></span></p></div>
                  <div><p><span class="num">{{ goPK }}</span><span v-show="isRate">%</span></p></div>
                </div>
              </el-card>
            </el-col>
            <el-col :span="6">
              <el-card class="box-card">
                <div slot="header" class="clearfix">
                  <div><p><span class="card-title">错题集</span><span></span></p></div>
                  <div><p><span class="num">{{ errorSet }}</span><span v-show="isRate">%</span></p></div>
                </div>
              </el-card>
            </el-col>
            <el-col :span="6">
              <el-card class="box-card">
                <div slot="header" class="clearfix">
                  <div><p><span class="card-title">智能知识库</span><span></span></p></div>
                  <div><p><span class="num">{{ knowledge }}</span><span v-show="isRate">%</span></p></div>
                </div>
              </el-card>
            </el-col>
            <el-col :span="6">
              <el-card class="box-card">
                <div slot="header" class="clearfix">
                  <div><p><span class="card-title">每周一答</span><span></span></p></div>
                  <div><p><span class="num">{{ answerEveryMonday }}</span><span v-show="isRate">%</span></p></div>
                </div>
              </el-card>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="8">
          <!--雷达图-->
          <div>
            <div id="radarChart" class="radar-chart-container" style="width:auto;height:350px;background: #fff;border-radius: 8px;"></div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="indexIottom">
      <el-row :gutter="20">
        <el-col :span="6" style="display: flex;flex-direction: column">
          <el-card class="box-card">
            <div slot="header" class="clearfix" >
              <div><p><span class="card-title">今日活跃</span><span></span></p></div>
              <div><p><span class="num">{{ toDayNum }}</span><span></span></p></div>
            </div>
          </el-card>
          <el-card class="box-card" style="margin-top: 30px">
            <div slot="header" class="clearfix" >
              <div><p><span class="card-title">本月活跃</span><span></span></p></div>
              <div><p><span class="num">{{ monthNum }}</span><span></span></p></div>
            </div>
          </el-card>
        </el-col>
        <el-col :span="18" style="width: 67%;padding: 0;">
            <el-button @click="selectDate('year')">年</el-button>
            <el-button @click="selectDate('month')">月</el-button>
            <el-button @click="selectDate('week')">周</el-button>
          <div id="activePeople" style="width: 100%;height:400px;background: #fff;border-radius: 8px;"></div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import {getLineChartData, getModuleHitsData} from "@/apis";
export default {
  data() {
    return {
      myStudy: 0,
      studyCalendar: 0,
      studying: 0,
      personalTraining: 0,
      goPK: 0,
      errorSet: 0,
      knowledge: 0,
      answerEveryMonday: 0,
      toDayNum: 0,
      monthNum: 0,
      timeDimension: 'day',
      listX: [],
      listY: [],
      radio: 1,
      dateList: '',
      startDate: '',
      endDate: '',
      statisticsVal: 1,
      isRate: false,
      radarList: []
    }
  },
  mounted() {
    this.getList();
    this.getMap1()
    this.selectDate('year')
  },
  methods: {
    reset(){
      this.timeDimension = 'day'
      this.radio = 1
      this.dateList = ''
      this.startDate = ''
      this.endDate = ''
      this.statisticsVal = 1
      this.isRate = false
      this.getList()
    },
    query(){
      this.getList()
    },
    async getList() {
      let _this = this
      const params = {
        timeDimension: this.timeDimension,
        startDate: this.startDate ? this.startDate: '',
        endDate: this.endDate ? this.endDate: '',
        statisticsVal: this.statisticsVal ? this.statisticsVal : ''
      }
      const { data } = await getModuleHitsData(params);
      if (data.code == 200) {
        if (this.statisticsVal && this.statisticsVal == 1) {
          _this.myStudy = data.data.myStudy? data.data.myStudy : 0;
          _this.studyCalendar = data.data.studyCalendar? data.data.studyCalendar : 0;
          _this.studying = data.data.studying? data.data.studying : 0;
          _this.personalTraining = data.data.personalTraining? data.data.personalTraining : 0;
          _this.goPK = data.data.goPK? data.data.goPK : 0;
          _this.errorSet = data.data.errorSet? data.data.errorSet : 0;
          _this.knowledge = data.data.knowledge? data.data.knowledge : 0;
          _this.answerEveryMonday = data.data.answerEveryMonday? data.data.answerEveryMonday : 0;
          _this.toDayNum = data.data.toDayNum? data.data.toDayNum : 0;
          _this.monthNum = data.data.monthNum? data.data.monthNum : 0;
          _this.radarList = []
          _this.radarList.push(data.data.myStudy)
          _this.radarList.push(data.data.studyCalendar)
          _this.radarList.push(data.data.studying)
          _this.radarList.push(data.data.personalTraining)
          _this.radarList.push(data.data.goPK)
          _this.radarList.push(data.data.errorSet)
          _this.radarList.push(data.data.knowledge)
          _this.radarList.push(data.data.answerEveryMonday)
          _this.isRate = false
          _this.getRadarMap()
        }else{
          if (!data.data.userNum || data.data.userNum == 0) {
            return
          }
          _this.myStudy = data.data.myStudy ? (data.data.myStudy * 100 / data.data.userNum).toFixed(1) : 0;
          _this.studyCalendar = data.data.studyCalendar ? (data.data.studyCalendar * 100 / data.data.userNum).toFixed(1) : 0;
          _this.studying = data.data.studying ? (data.data.studying * 100 / data.data.userNum).toFixed(1) : 0;
          _this.personalTraining = data.data.personalTraining ? (data.data.personalTraining * 100 / data.data.userNum).toFixed(1) : 0;
          _this.goPK = data.data.goPK ? (data.data.goPK * 100 / data.data.userNum).toFixed(1) : 0;
          _this.errorSet = data.data.errorSet ? (data.data.errorSet * 100 / data.data.userNum).toFixed(1) : 0;
          _this.knowledge = data.data.knowledge ? (data.data.knowledge * 100 / data.data.userNum).toFixed(1) : 0;
          _this.answerEveryMonday = data.data.answerEveryMonday ? (data.data.answerEveryMonday * 100 / data.data.userNum).toFixed(1) : 0;
          _this.toDayNum = data.data.toDayNum ? data.data.toDayNum : 0;
          _this.monthNum = data.data.monthNum ? data.data.monthNum : 0;
          _this.radarList = []
          _this.radarList.push(data.data.myStudy)
          _this.radarList.push(data.data.studyCalendar)
          _this.radarList.push(data.data.studying)
          _this.radarList.push(data.data.personalTraining)
          _this.radarList.push(data.data.goPK)
          _this.radarList.push(data.data.errorSet)
          _this.radarList.push(data.data.knowledge)
          _this.radarList.push(data.data.answerEveryMonday)
          _this.isRate = true
          _this.getRadarMap()
        }
      }
    },
    getMap1() {
      var myChart = this.$Echarts.init(document.getElementById('activePeople'));
      // 指定图表的配置项和数据
      var option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        title: {
          text: '活跃人数',
          left: 'left'
        },
        xAxis: {
          type: 'category',
          data: this.listX
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: this.listY,
            type: 'line',
            smooth: true
          }
        ]
      };
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);

    },
    getRadarMap() {
      let _this= this
      var myChart = this.$Echarts.init(document.getElementById('radarChart'));
      var radarOption= {
        title: {
          text: ''
        },
        tooltip: {
          trigger: 'item',
          formatter: function (params) {
            debugger
            let tipContent = '';
            tipContent += params.data.name +':'+ params.data.value + '<br>';
            return tipContent;
          }
        },
        legend: {
          orient: 'vertical',
          left:'left'
        },
        radar: {
          indicator: [
            {name: '我的学习', max: 100},
            {name: '学习日历', max: 100},
            {name: '正在学', max: 100},
            {name: '个人训练', max: 100},
            {name: '去PK', max: 100},
            {name: '错题集', max: 100},
            {name: '智能知识库', max: 100},
            {name: '每周一答', max: 100}
          ]
        },
        axisLabel: {
          interval: 0,
          formatter: function (value) {
            return value;
          },
          textStyle: {
            fontSize: 12, // 设置字体大小
            color: '#333' // 设置字体颜色
          }
        },
        series: [
          {
            name: '',
            type: 'radar',
            data: [
              {
                value : _this.radarList,
                name: ['我的学习','学习日历','正在学', '个人训练', '去PK', '错题集', '智能知识库', '每周一答'],
              }
            ]
          }
        ]
      }
      // 使用刚指定的配置项和数据显示图表。
      myChart.clear();
      myChart.setOption(radarOption);
    },
    clickDate(value){
      this.dateList = [];
      if(this.radio){
        if(this.radio == 4){
          this.timeDimension = 'year'
        }else if(this.radio == 3){
          this.timeDimension = 'month'
        }else if(this.radio == 2){
          this.timeDimension = 'week'
        }else{
          this.timeDimension = 'day'
        }
      }
    },
    clickDatePicker(){
      this.radio = ''
      if(this.dateList && this.dateList.length == 2){
        // 日期转成 yyyy-MM-dd
        this.startDate =this.dateList[0];
        this.endDate = this.dateList[1];
        this.timeDimension = ''
      }

    },
    clickRate(){
      console.log('statisticsVal',this.statisticsVal);
      if(!this.statisticsVal || this.statisticsVal.length == 0){
        this.$message.warning('参数丢失')
        return ;
      }
    },
    async selectDate(val) {
      let _this = this
      if (val) {
        let params = {
          timeDimension: val
        };
        const { data } = await getLineChartData(params);
        if (data.code = 200) {
          _this.listX = []
          _this.listY = []
          if(val == 'year'){
            data.data.forEach(item => {
              _this.listX.push(item.name + '月')
              _this.listY.push(item.num)
            })
          }else{
            data.data.forEach(item => {
              _this.listX.push(item.name)
              _this.listY.push(item.num)
            })
          }
          _this.getMap1()
        }
      }
    }

  }
}
</script>

<style scoped>
.clearfix {
  display: flex;
  flex-direction: column;
}
.card-title {
  font-size: 26px;
}
.num {
  font-size: 30px;
  font-weight: bold;
}
.indexIottom{
   margin-top: 50px;
}
</style>
