<!-- 题库管理首页 -->
<template>
  <div>
    <div class="main">
      <div style="font-size: 26px; font-weight: bold">意见反馈</div>
      <div>
        <div>
          <!-- <el-button
            type="primary"
            style="height: 34px; line-height: 10px; margin-right: 45px"
            class="cinput"
            @click='add'
            >+添加分类</el-button
          > -->
          <!-- <el-radio-group v-model="radio" style="height: 34px" class="cinput">
            <el-radio-button :label="item.id" v-for='(item,index) in qslist' :key='index'>{{item.name}}</el-radio-button>
            <el-radio-button label="判断题"></el-radio-button>
            <el-radio-button label="填空题"></el-radio-button>
            <el-radio-button label="主观题"></el-radio-button>
            <el-radio-button label="看图说话"></el-radio-button>
          </el-radio-group> -->
        </div>
        <div>
            <!-- <el-select
            clearable
            v-model="levelId"
            placeholder="等级筛选"
            class="cinput ac"
            style="margin: 0 23px"
            @change="list"
          >
            <el-option
              v-for="item in levelLists"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select> -->
          <!-- <el-select
            v-model="timeState"
            placeholder="状态筛选"
            class="cinput ac"
            style="margin: 0 23px"
          >
            <el-option
              v-for="item in timeStateList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <el-select
            v-model="type"
            placeholder="类型筛选"
            class="cinput ac"
            style="margin: 0 23px"
          >
            <el-option
              v-for="item in typeList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select> -->
          <!-- <div style="width: 221px">
            <el-input
              v-model="input"
              placeholder="请输入搜索内容"
              class="cinput ac"
              @keyup.enter.native="list"
            ></el-input>
            <div
              style="
                display: flex;
                height: 34px;
                width: 34px;
                align-items: center;
                background: #f7f7f7;
              "
            >
              <img
                v-if="isshow"
                src="../../images/search2.png"
                alt=""
                @click="list"
                style="background: #f7f7f7"
              />
              <img
                v-if="isshow == false"
                src="../../images/search1.png"
                alt=""
                style="background: #f7f7f7"
              />
            </div>
          </div> -->
        </div>
      </div>

      <el-table
        :header-cell-style="{ background: '#F7F7F7' }"
        :data="tableData"
        style="width: 100%; min-height: 600px"
         @selection-change="handleSelectionChange"
      >
      <el-table-column
      type="selection"
      align='center'
      width="55"/>
        <el-table-column type="index" width="50"> </el-table-column>
        <el-table-column prop="userName" align="center" label="用户名" :show-overflow-tooltip="true" >
        </el-table-column>
        <el-table-column prop="ctime" align="center" label="反馈时间" :show-overflow-tooltip="true" >
        </el-table-column>
         <el-table-column prop="utime" align="center" label="解决时间" :show-overflow-tooltip="true" >
        </el-table-column>
        <el-table-column prop="contact" align="center" label="联系方式" :show-overflow-tooltip="true" >
        </el-table-column>

        <el-table-column prop="des" align="center" label="反馈类型" :show-overflow-tooltip="true" >
          <template slot-scope="scope">
            <div>
              {{scope.row.type==1?'小程序':scope.row.type==2?'教学内容':'刷题/考试'}}
            </div>
          </template>
        </el-table-column>
         <el-table-column prop="feedbackContent" label="反馈内容" align="center" max-width="200" :show-overflow-tooltip="true">
        </el-table-column>
        <!-- <el-table-column prop="startTime" align="center" label="图片" :show-overflow-tooltip="true" >
          <template slot-scope="scope">
            <div>
              <img :src='item' v-for="item in scope.row.resourceUri" style="width:100%;height:100%">
            </div>
          </template>
        </el-table-column> -->


        <el-table-column prop="address" label="操作" align="center" width="200">
          <template slot-scope="scope">
            <div style="display: flex; justify-content: center">
             <el-link type="primary" :underline="false" @click='deal(scope.row)' style="margin:0 20px" v-if="scope.row.processState==0">处理</el-link>
              <el-link type="primary" :underline="false" @click='details(scope.row)' v-if="scope.row.processState!=0">查看详情</el-link>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: flex-end;
        "
      >
        <!-- <el-button type="primary" @click='deleall' :disabled='isdisabled'>删除选中</el-button> -->
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageIndex"
          :page-sizes="[10, 15, 20]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>

  <el-dialog
  title="反馈图片"
  :visible.sync="dialogVisible"
  width="80%"
  :before-close="handleClose" :close-on-click-modal="false">
  <div>
      <div>
        反馈是否有效 <span style="color: red">*</span>
        <el-radio-group v-model="processState" prop="processState">
        <el-radio :label="9">有效</el-radio>
        <el-radio :label="1">无效</el-radio>
      </el-radio-group
      ></div>
      <div style="margin-top: 20px">
      反馈内容 <span style="color: red">*</span>
        <el-input disabled v-model="feedbackContent" prop="feedbackContent" placeholder="请输入" type="textarea" :row="3"></el-input>
      </div>
    <div style="margin-top: 20px">
      反馈处理内容 <span style="color: red;">*</span>
      <el-input v-model="processContent" prop="processContent" placeholder="请输入" type="textarea" :row="3"></el-input>
    </div>
      <div>
      <img :src='item' v-for="item in resourceUri" style="width:200px;height:200px"  @click='des(item)' />
    </div>
  <img :src='resourceUriDes' style="width:50%;height:1000px" v-show="resourceUriDes"/>
  </div>
  <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="submit" v-if="vShow">确 定</el-button>
  </span>
</el-dialog>


  </div>
</template>

<script>
import {
  questiontypeList,
  qsList,
  levelList,
  qssaveOrUpdate,
  PaperList,
  delePaper,
  goodsClassList,
  deleGoodsClass,
  getOpinion,
  updatefeedback,
  addPoint, checkPointSum
} from "../../apis/index";
export default {
  data() {
    return {
      vShow: true,
      processState: '',
      feedbackContent: '',
      processContent: '',
      dialogVisible:false,
      resourceUriDes:'',
      resourceUri:[],
        levelId:'',
      date:'',
      timeState:'',
      timeStateList:[
        {
          id:1,
          name:'未生效'
        },
        {
          id:2,
          name:'已生效'
        },
        {
          id:3,
          name:'已过期'
        },
      ],
       typeList:[
                 {
                    id:1,
                    name:'小程序'
                },{
                    id:2,
                    name:'课程包'
                }
            ],
            type:'',//问卷类型
      pageIndex: 1,
      pageSize: 10,
      total:0,
      qslist:[],
      levelLists:[],
      value: "",
      isshow: false,
      isshow2: true,
      isdisabled:true,
      currentPage: 1,
      radio: "1",
      input: "",
      tableData: [

      ],
    };
  },
  watch: {
    input(newdata, olddata) {
     if(newdata==''){
        this.isshow=false
       this.input=''
       this.list()
     }else{
       this.isshow=true
     }
    },
    radio(newdata, olddata) {
     this.list()
    },
    value(){
      this.list()
    },
  },
  created(){
    // let date=new Date().toLocaleString( )
    // let date1=date.replace(/\//g,'-')
    // this.date=new Date(date1).getTime()
    // this.questype()
    this.list()
    // this.leave()
  },
  //   beforeRouteLeave(to, from, next) {
  //   if(to.path=='/exercisesAdd'){//添加页，就不缓存
  //     this.$route.meta.keepAlive=false
  //     setTimeout(() => {
  //       next()
  //     }, 100);
  //   }else{//详情页缓存
  //    this.$route.meta.keepAlive=true
  //     setTimeout(() => {
  //       next()
  //     }, 100);
  //   }
  //   // 导航离开该组件的对应路由时调用
  //   // 可以访问组件实例 `this`
  // },
  filters:{
    filtersDate(date){
     return new Date(date).getTime()
    },
  },
  methods: {
    submit(){
      if (!this.processState) {
        this.$message.warning('请选择是否有效')
        return
      }
      if (!this.processContent) {
        this.$message.warning('请输入反馈处理内容')
        return
      }
      const params={
        id:this.form.id,
        processContent: this.processContent,
        processState: this.processState,
        feedbackContent: this.feedbackContent
      }
      this.$confirm('请确认是否已解决?若反馈有效，会发放10个松果！', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        updatefeedback(params).then(res=>{
          if(res.data.code==200){
            this.$message({
              type: 'success',
              message: '操作成功!'
            });
            // 有效

            if (this.processState == 9) {
              // 采纳的话给用户添加10个松果
              let paramsSum = {
                userId: this.form.userId,
                accessPassType: 9
              }
              // 验证松果增加了几次，10次就不再增加
              checkPointSum(paramsSum).then(async res => {
                if (res.data && res.data.code == 200) {
                  if (res.data.data < 10) {
                    let params = {
                      userId: this.form.userId,
                      point: 10,
                      accessPass: '反馈采纳',
                      accessPassType: 9
                    }
                    addPoint(params).then(res => {
                      if (res.data.code == 200) {
                      }
                    })
                  }
                }
              })
            }
            this.dialogVisible = false
            this.list()
          }else{
            this.$message({
              type: 'error',
              message: res.data.msg
            });
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });

    },
     deal(row) {
      this.processState = ''
       this.form=row
       this.processState = row.processState?row.processState:''
       this.feedbackContent = row.feedbackContent?row.feedbackContent:''
       this.vShow=true
       if(row.resourceUri){
         let resourceUri=row.resourceUri
         this.resourceUri=resourceUri
         this.resourceUriDes=resourceUri[0]
       }else{
         this.resourceUri=''
         this.resourceUriDes=''
       }
       this.dialogVisible=true
      },
    look(item){//查看统计
    console.log(item)
    let items=JSON.stringify(item)
      this.$router.push({path:'/surveyPapgeDetails2',query:{items}})
    },
   async leave() {
      //获取等级列表
      const { data } = await levelList({roleId:3});
      if (data.code == 200) {
        this.levelLists = data.data;
      } else {
        this.$message.error(data.msg);
      }
    },
   async list(){
      const params={
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        levelId:this.levelId
        // timeState:this.timeState,
        // type:1
      }
     const {data}=await getOpinion(params)
     if(data.code==200){
      //  let list=data.data.records.map(item=>{
      //    item.gettime=new Date(item.startTime).getTime()
      //    return item
      //  })
       this.tableData=data.data.records
       this.total=Number(data.data.total)
     }
    },
     async questype() {//题目类型列表
      const { data } = await questiontypeList();
      let qslist=data.data
      this.qslist = qslist;

    },
    add(){//添加题目
      this.$router.push({path:'/mallClass'})
    },
    handleClose(){
      this.dialogVisible=false
    },
    details(row){//查看详情
      this.processState = row.processState?row.processState:''
      this.feedbackContent = row.feedbackContent?row.feedbackContent:''
      this.vShow = false

    if(row.resourceUri){
      let resourceUri=row.resourceUri
      // console.log(resourceUri)
      this.resourceUri=resourceUri
      this.resourceUriDes=resourceUri[0]
      this.dialogVisible=true
    }else{
      this.resourceUriDes = ''
      this.resourceUri = []
     /* this.$message({
                type: 'error',
                message: "暂无详情图片"
              });*/
      this.dialogVisible = true
    }

      // this.$router.push({path:'/mallClass',query:{row:JSON.stringify(row)}})
    },
    des(item){
      this.resourceUriDes=item
    },
     details2(row){//添加试题
      this.$router.push({path:'/surveyPapgeAdd2',query:{row:JSON.stringify(row)}})
    },
    search() {
      console.log("我是搜索");
    },
     handleSelectionChange(val) {//选中
        console.log(val)
        if(val.length>0){
          this.isdisabled=false
        }else{
          this.isdisabled=true
        }
      },
    handleSizeChange(val) {
      this.pageSize=val
      console.log(`每页 ${val} 条`);
      this.list()
    },
    handleCurrentChange(val) {
      this.pageIndex=val
      console.log(`当前页: ${val}`);
      this.list()
    },
     dele(row) {
        const params={
         id:row.id,
       }
        this.$confirm('此操作将永久删除该项, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
           deleGoodsClass(params).then(res=>{
            if(res.data.code==200){
              this.$message({
                type: 'success',
                message: '删除成功!'
              });
              this.list()
            }else{
              this.$message({
                type: 'error',
                message: res.data.msg
              });
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      },
       deleall() {
        this.$confirm('此操作将永久删除选中项, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      }
  },
};
</script>

<style lang='less' scoped>
.main {
  // min-height: 800px;
  background: #fff;
  margin: 19px 0;
  padding: 23px;
  border-radius: 14px;
}
.main > div:first-child {
  font-size: 26px;
  // font-weight: bold;
}
.main > div:nth-child(2) {
  display: flex;
  justify-content: space-between;
  height: 34px;
  margin: 18px 0;
  div {
    display: flex;
    img {
      width: 25px;
      height: 25px;
    }
  }
}
/deep/.el-radio-button__inner {
  height: 34px;
  line-height: 10px;
}
.cinput /deep/.el-input__inner {
  height: 34px;
  line-height: 10px;
}
/deep/.el-button--primary {
  height: 34px; line-height: 10px;
}
/deep/.el-table::before {
  height: 0;
}
.ac /deep/.el-input__inner {
  border: none;
  background: #f7f7f7;
  // margin-right: 11px;
}
.red {
  color: #ff1b0b;
}
</style>
