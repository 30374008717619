<!-- 推荐管理 -->
<template>
    <div class="main">
        <div class="topTitlesTrue" v-show="navigationBarList.length == 0">
            <div class="title">分类管理</div>
            <div class="addBtn" @click="addFileBtn">+新增分类</div>
        </div>
        <div class="menuList" v-show="navigationBarList.length == 0">
            <div class="menuItem" v-for="(item, index) in topTitleList" :key="index" @click="clickTopTitleList(item)">
                <div class="titleName">{{ item.name }}</div>
                <div class="icons">
                    <img src="../../images/drag.png" alt="" class="drag">
                    <el-dropdown @command="handleCommand">
                        <img src="../../images/menuMore.png" alt="" class="more">
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item v-for="(item2, index) in dropdownList"
                                :command="composeValue(item2.value, item)" :key="index">{{ item2.name
                                }}</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
            </div>
        </div>
        <div class="navigationList" v-show="navigationBarList.length > 0">
            <span class="goback" @click="goback">
                < </span>
                    <div class="navigationItem" v-for="(item, index) in navigationBarList" :key="index"
                        @click="clickNavigationList(item, index)">
                        <div :class="index + 1 == navigationBarList.length ? 'navigationNameCurrent' : 'navigationName'">{{
                            item.name }} <span v-if="index + 1 != navigationBarList.length">-</span> </div>
                    </div>
        </div>
        <div class="mainContent">
            <div class="menuFile">
                <div class="topTitles">
                    <div class="fileManagement">
                        <div class="titleLine"></div> 文件夹管理
                      <div class="item" style="align-items: ccenter;" v-show="brandIdSelectBigShow">
                        <div class="" style="align-items: baseline;margin-left: 20px">品牌:</div>
                        <el-select v-model="brandIdSelectBig" placeholder="请选择" clearable @change="brandIdSelectBigChange">
                          <el-option v-for="item in brandList" :key="item.id" :label="item.name" :value="item.id">
                          </el-option>
                        </el-select>
                      </div>
                    </div>
                    <div class="addFile" @click="addFileBtn" v-if="parentId != 0">+新增文件夹</div>
                </div>
                <el-table :header-cell-style="{ background: '#F7F7F7' }" :data="currentFileList.children"
                    style="width: 100%; ">
                    <el-table-column prop="id" align="center" label="id" width="50"></el-table-column>
                    <el-table-column prop="name" align="center" label="文件夹名称">
                        <template slot-scope="scope">
                            <div style="display:flex">
                                <div :class="[scope.row.isValidity == 1 ? 'isValidityTrue' : 'isValidityFalse']" style="width: 20px;height: 20px;border-radius: 50%;flex-shrink: 0;">
                                    <!-- {{ scope.row.isValidity == 1 ? '已启用' : '已停用' }} -->
                                </div>
                                <div>{{ scope.row.name }}</div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="weight" align="center" label="权重" width="50"></el-table-column>
                    <el-table-column prop="brandName" align="center" label="子文件夹数量">
                        <template slot-scope="scope">
                            <div>
                                {{ scope.row.children ? scope.row.children.length : 0 }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="agentName" align="center" label="素材数量" width="100"></el-table-column>
                    <el-table-column prop="vipTime" label="操作">
                        <template slot-scope="scope">
                            <div class="tableMenu">
                                <div @click="enterFile(scope.row)" style="color: #1890FF;">进入</div>
                                <div class="operationStatus"
                                    :style="{ 'color': scope.row.isValidity == 1 ? '#FF4D4F' : '#52C41A' }"
                                    @click="switchisValidity(scope.row)">
                                    {{ scope.row.isValidity == 1 ? '停用' : '启用' }}</div>
                                <el-dropdown @command="handleCommand">
                                    <img src="../../images/menuMore.png" alt="" style="width: 24px;height: 24px;">
                                    <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item v-for="(item, index) in dropdownList"
                                            :command="composeValue(item.value, scope.row)" :key="index">{{ item.name
                                            }}</el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="menuContent">
                <div class="topTitles">
                    <div class="fileManagement">
                        <div class="titleLine"></div>内容管理
                    </div>
                    <div class="addFile" @click="addContentBtn" v-if="parentId != 0">+新增内容</div>
                </div>
                <div class="gridLayout">
                    <!-- <div class="display">
                        <div class="fs0 f14">品牌筛选：</div>
                        <el-select v-model="brandId" placeholder="请选择" class="my_inpuf" clearable multiple
                            @change="contentFilter">
                            <el-option v-for="item in brandList" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </div> -->
                    <div class="display">
                        <div class="fs0 f14">身份筛选：</div>
                        <el-select v-model="tag" placeholder="请选择" class="my_inpuf" clearable @change="contentFilter">
                            <el-option v-for="item in idList" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </div>
                    <!-- <div></div> -->
                    <!-- <div style="display: flex;justify-content: flex-end;">
                        <div class="addFile" @click="addContentBtn" v-if="parentId != 0">+新增内容</div>
                    </div> -->
                </div>
                <el-table :header-cell-style="{ background: '#F7F7F7' }" :data="contentList" style="width: 100%; "
                    v-loading="loading" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading">
                    <el-table-column prop="content.content" align="center" label="文案"
                        show-overflow-tooltip></el-table-column>
                    <el-table-column prop="vip" align="center" label="资源" width="150">
                        <template slot-scope="scope">
                            <div>
                                <div v-if="scope.row.imgList"
                                    style="display: grid;grid-template-columns: 1fr 1fr 1fr;gap: 5px;">
                                    <img :src="item.resourceUrl" alt="" v-for="(item, index) in scope.row.imgList"
                                        :key="index" style="width: 100%;height: 100%;">
                                </div>
                                <div v-if="scope.row.video">
                                    <video :src="scope.row.video.resourceUrl" alt="" controls style="width: 100%;"></video>
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="brandName" align="center" label="权重" width="50"></el-table-column>
                    <el-table-column prop="agentName" align="center" label="身份">
                        <template slot-scope="scope">
                            <div v-if="scope.row.info.tagId">
                                {{ scope.row.info.tagId | idFilter(idList) }}
                            </div>
                        </template>
                    </el-table-column>
                    <!-- <el-table-column prop="agentName" align="center" label="品牌">
                        <template slot-scope="scope">
                            <div>
                                {{ scope.row.info.brandBinarySum | brandFilter }}
                            </div>
                        </template>
                    </el-table-column> -->
                    <el-table-column prop="vipTime" label="操作" align="center">
                        <template slot-scope="scope">
                            <div class="tableMenu" style="flex-direction: column;">
                                <div @click="editInfo(scope.row)" style="color: #005FD3;">编辑</div>
                                <div @click="deleteMaterial(scope.row)" style="color: #FF4D4F;">删除</div>
                                <div @click="moveContent(scope.row)" style="color: #00A78E;">移动</div>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>

        <el-dialog title="新增文件夹" :visible.sync="fileVisible" width="30%">
            <el-form ref="form" label-width="100px">
                <el-form-item label="文件夹名称">
                    <el-input v-model="name"></el-input>
                </el-form-item>
                <el-form-item label="文件夹权重">
                    <el-input v-model="weight"></el-input>
                </el-form-item>
                <el-form-item label="文件夹状态">
                    <el-switch v-model="isValidity" active-color="#13ce66" inactive-color="#ff4949">
                    </el-switch>
                </el-form-item>

            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="cancelFileMethod">取 消</el-button>
                <el-button type="primary" @click="fileMethod">确 定</el-button>
            </span>
        </el-dialog>

        <el-dialog title="新增内容" :visible.sync="contentVisible" width="30%">
            <div>
                <!-- <div class="item" style="align-items: flex-start;">
                    <div class="f14 c7e myTitle">品牌:</div>
                    <el-select v-model="brandIdSelect" placeholder="请选择" clearable multiple>
                        <el-option v-for="item in brandList" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </div> -->
                <div class="item" style="align-items: flex-start;">
                    <div class="f14 c7e myTitle">身份:</div>
                    <el-select v-model="tagSelect" placeholder="请选择" clearable>
                        <el-option v-for="item in idList" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </div>
                <div class="item" style="align-items: flex-start;">
                    <div class="f14 c7e myTitle">内容:</div>
                    <el-input v-model="content" placeholder="请输入内容" class="long" type="textarea" maxlength="108" :rows="5"
                        show-word-limit></el-input>
                </div>
                <div class="item">
                    <div class="f14 c7e myTitle">类型选择:</div>
                    <div class="playSet">
                        <el-radio-group v-model="mediaType" text-color="#00A78E">
                            <el-radio :label="item.value" v-for="(item, index) in mediaTypeList" :key="index">{{ item.label
                            }}</el-radio>
                        </el-radio-group>
                    </div>
                </div>
                <div class="item" style="align-items: flex-start;" v-show="mediaType == 2">
                    <div class="f14 c7e myTitle">图片:</div>
                    <div class="imgListWrap">
                        <div class="imgShowClass" v-for="(item, index) in imgList" :key="index">
                            <img :src="item.resourceUrl" alt="" style="width: 100%;height: 100%;">
                            <div class="clearImg" @click="deleteImg(item, index)">删除</div>
                        </div>
                        <div class="uploadDemo">
                            <img src="../../images/uploadImg.png" alt="" class="upload" @click="uploadCoursePage">
                            <input type="file" class="uploadInput" id="picUri" @change="chooseWebFileChange"
                                accept="image/*" />
                        </div>
                    </div>
                </div>
                <div class="item" style="align-items: flex-start;" v-show="mediaType == 3">
                    <div class="f14 c7e myTitle">视频:</div>
                    <div class="imgListWrap">
                        <video :src="videoUrl" v-if="videoUrl" style="width: 100%;" controls></video>
                        <div @click="deleteVideo" v-if="videoUrl" style="position: absolute;top: 10px;right: 10px;">删除</div>
                        <el-progress type="circle" :percentage="percentage"
                            style="width: 104px;height: 104px;justify-content: center;align-items: center;"
                            v-if="!videoUrl && percentage > 0"></el-progress>
                        <div class="uploadDemo" v-if="!videoUrl && percentage <= 0">
                            <img src="../../images/uploadVideo.png" alt="" class="upload" @click="uploadmedia">
                            <input type="file" class="uploadInput" id="videoUrl" ref="videoUrl"
                                @change="chooseWebMediaChange" accept="video/*" />
                        </div>
                    </div>
                </div>

            </div>



            <span slot="footer" class="dialog-footer">
                <el-button @click="cancelContentMethod">取 消</el-button>
                <el-button type="primary" @click="contentMethod">确 定</el-button>
            </span>
        </el-dialog>


        <el-dialog title="移动内容" :visible.sync="fileVisibleContent" width="30%">
            <div style="display: flex;align-items: center;"><span style="flex-shrink: 0;">请输入目标文件夹Id：</span><el-input
                    v-model="targetWarehouseId"></el-input></div>

            <span slot="footer" class="dialog-footer">
                <el-button @click="cancelContentMove">取 消</el-button>
                <el-button type="primary" @click="confirmContentMove">确 定</el-button>
            </span>
        </el-dialog>


    </div>
</template>

<script>
import uploadImg from '../../utils/uploadImg'
import { moveContentApi, getWarehouseApi, saveWarehouseApi, deleteWarehouseApi, getContentApi, getTagApi, uploadSignature, materialInfoApi, materialResource, materialResources, weChatMiniProgramPublish } from '../../apis/index'
export default {
    data() {
        return {
            brandIdSelectBig: '',
            brandIdSelectBigShow: false,
            fileVisibleContent: false,
            imgList: [],
            mediaTypeList: [//type 1 文字 2 图片 3视频
                {
                    value: '2',
                    label: '图片'
                },
                {
                    value: '3',
                    label: '视频'
                }
            ],
            mediaType: '2',//
            brandList: [//binary
                {
                    id: 1,
                    name: '嘉顿肯妮'
                },
                {
                    id: 2,
                    name: '瑞妮朵拉'
                },
                {
                    id: 4,
                    name: '素颜+'
                },
                {
                    id: 8,
                    name: '外部学员'
                }
            ],
            brandId: '',
            dropdownList: [
                {
                    name: '编辑',
                    value: 'a'
                },
                {
                    name: '删除',
                    value: 'b'
                }
            ],
            fileVisible: false,
            contentVisible: false,
            name: '',//文件夹名称
            weight: '',//文件夹权重
            parentId: 0,
            warehouseObj: {},
            warehousemap: [],
            currentFileList: [],//当前所展示的文件夹
            navigationBarList: [],//导航栏列表
            fileId: '',
            haveInfo: 0,// 1 表示有内容
            contentList: [],//内容列表
            idList: [],//身份列表
            tag: '',
            tagSelect: '',//新增内容用
            content: '',//内容
            percentage: 0,
            videoUrl: '',
            resourceId: '',
            brandIdSelect: '',//新增内容用
            contentId: '',//编辑内容id
            showObj: {},
            deleteList: [],//删除列表
            addImgList: [],//新增的图片列表
            contentObj: {},//内容对象
            loading: false,
            originList: [],//原始内容列表
            isValidity: true,
            moveContentObj: {},
            targetWarehouseId: '',//要移动的目标文件夹id
            isMove:false,//是否移动还是增加
        }
    },
    computed: {
        topTitleList() {//最顶层文件夹
            return this.warehouseObj.children
        },
    },
    watch:{
        parentId(newd,old){
            console.log(newd,old)
        },
    },
    filters: {
        brandFilter(value) {//品牌过滤器
            if (!value) return ''
            var arr = []
            if ((value & 1) == 1) {
                arr.push('嘉顿肯妮')
            }
            if ((value & 2) == 2) {
                arr.push('瑞妮朵拉')
            }
            if ((value & 4) == 4) {
                arr.push('素颜+')
            }
            if ((value & 8) == 8) {
                arr.push('外部品牌')
            }
            return arr.join('、')
        },
        idFilter(value, idList) {//身份过滤器
            // if(!value) return ''
            let obj = idList.find(item => { return item.id == value })
            return obj.name
        },
    },
    created() {
        this.getWarehouseList()
        this.getIdList()
        const testArr = [
            [1],
            [2],
            [1, 2],
            [1, 2, 4],
        ];
        const test = [1, 2];
        for (let i = 0; i < testArr.length; i++) {
            console.log(this.isContained(testArr[i], test), i);
        }
    },
    methods: {
        cancelContentMove() {//取消移动
            this.fileVisibleContent = false
        },
        confirmContentMove() {//确认移动弹窗
            this.$confirm('此操作将移动内容, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.confirmContentMoveMethods()
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });
                this.fileVisibleContent = false
            });
        },
        async confirmContentMoveMethods() {//移动内容方法
            const params = this.moveContentObj
            if (!this.targetWarehouseId) {
                this.$message.success('请输入目标文件夹id')
                return
            }
            params.warehouseId = this.targetWarehouseId
            const { data } = await moveContentApi(params)
            if (data.code == 200) {
                this.$message.success(data.msg)
                this.fileVisibleContent=false
                this.targetWarehouseId=''
                this.isMove=true
                setTimeout(() => {
                    this.getContentList()
                    this.getWarehouseList()
                }, 100);
            } else {
                this.$message.error(data.msg)
            }
        },
        moveContent(row) {//移动按钮
            let moveWareHouseResourceIdList = row.resources.map(i => { return i.id })
            const moveContentObj = {
                id: row.content.id,
                tagId: row.info.tagId,
                moveWareHouseResourceIdList
            }
            this.moveContentObj = moveContentObj
            this.fileVisibleContent = true
        },
        moveContentConfirm() {

        },
        async switchisValidity(item) {//文件夹状态切换
            const params = {
                id: item.id,
                name: item.name,
                isValidity: item.isValidity ? 0 : 1,
                weight: item.weight,
                parentId: item.parentId
            }
            const { data } = await saveWarehouseApi(params, 'put')
            if (data.code == 200) {
                this.$message.success(data.msg)
                setTimeout(() => {
                    this.getWarehouseList()
                }, 100)
            } else {
                this.$message.error(data.msg)
            }
        },
        contentFilter() {
            let originList = this.originList
            let brandId = this.brandId
            let tag = this.tag
            this.contentList = originList.filter(item => { return !tag || item.info.tagId == tag })
                .filter(item2 => { return this.isContained(item2.brandList, brandId) || !brandId })

          this.getContentList()
        },
        isContained(a, b) {
            // a和b其中一个不是数组，直接返回false
            if (!(a instanceof Array) || !(b instanceof Array)) return false;
            const len = b.length;
            // a的长度小于b的长度，直接返回false
            // if (a.length < len) return false;
            for (let i = 0; i < len; i++) {
                // 遍历b中的元素，遇到a没有包含某个元素的，直接返回false
                if (!a.includes(b[i])) return false;
            }
            // 遍历结束，返回true
            return true;
        },
        deleteImg(item, index) {//删除图片
            let that = this
            this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                that.deleteList.push(item)
                that.imgList.splice(index, 1)
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        deleteVideo() {//删除视频
            let that = this
            this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                that.deleteList.push(this.showObj.video)
                that.videoUrl = ''
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        async resourceMethod(method) {//资源删除
            let deleteList = this.deleteList
            const params = deleteList.map(item => {
                return {
                    downCount: item.downCount,
                    id: item.id,
                    materialId: item.infoId,
                    materialWarehouseId: item.wareHouseId,
                    resourceUrl: item.resourceUrl,
                    resourceId: item.resourceId ? item.resourceId : '',
                    type: item.type
                }
            })
            try {
                const { data } = await materialResources(params, method)
                if (data.code == 200) {
                    this.getContentList()
                    this.$message.success(data.msg)
                    this.deleteList = []
                } else {
                    this.$message.error(data.msg)
                }
            } catch (e) {
                console.log(e)
                this.$message.error('请求异常')
            }
        },
        async updateResourceMethod(method) {//修改、添加内容资源
            let params = {}
            if (this.contentObj) {
                params = JSON.parse(JSON.stringify(this.contentObj))
            } else {
                params = {
                    materialId: this.contentId,
                    materialWarehouseId: this.parentId,
                    type: 1
                }
            }
            params.content = this.content
            try {
                const { data } = await materialResource(params, method)
                if (data.code == 200) {
                    setTimeout(() => {
                        this.getContentList()
                    }, 100);

                    this.$message.success(data.msg)
                } else {
                    this.$message.error(data.msg)
                }
            } catch (e) {
                console.log(e)
                this.$message.error('请求异常')
            }
        },
        async saveResourceMethod(res) {//单个保存资源的方法
            const params = {
                downCount: 0,
                id: 0,
                materialId: this.contentId,
                materialWarehouseId: this.parentId,
                resourceUrl: res,
                resourceId: this.resourceId,
                type: this.mediaType
            }
            try {
                const { data } = await materialResource(params, 'post')
                if (data.code == 200) {
                    // this.getContentList()
                    this.$message.success(data.msg)
                } else {
                    this.$message.error(data.msg)
                }
            } catch (e) {
                console.log(e)
                this.$message.error('请求异常')
            }
        },
        uploadCoursePage() {//点击上传图片图片触发上传图片input操作
            document.getElementById('picUri').click()
        },
        chooseWebFileChange(e) {//上传图片
            let file = document.getElementById('picUri').files[0]
            if (file) {
                uploadImg(file, (res) => {
                    this.imgList.push({ resourceUrl: res })
                    if (this.contentId) {
                        this.saveResourceMethod(res)
                    }
                    document.getElementById('picUri').value = ''
                })
            }
        },
        uploadmedia() {//上传视频
            document.getElementById('videoUrl').click()
        },
        chooseWebMediaChange(e) {//上传视频
            if (this.$refs["videoUrl"].files[0]) {
                let selectFileobj = this.$refs["videoUrl"].files[0];
                console.log(selectFileobj)

                this.uploadYdb(selectFileobj)//云点播上传视频
            }
        },
        async getUploadSignature() {//获取云点播上传凭证
            const { data } = await uploadSignature()
            if (data.code == 200) {
                sessionStorage.setItem('getUploadSignature', data.data.getUploadSignature)
                return data.data.getUploadSignature
            }
            console.log(data.data.getUploadSignature)
        },
        uploadYdb(selectFileobj) {//云点播上传视频
            let that = this
            let getVideoSignature = () => {
                return that.getUploadSignature()
            }
            const tcVod = new that.$TcVod({
                getSignature: getVideoSignature // 前文中所述的获取上传签名的函数
            })
            let name = selectFileobj.name
            let filename = 'material' +
                name.slice(0, name.length - 4) +
                new Date().getTime() +
                name.slice(-4);
            const uploader = tcVod.upload({
                mediaFile: selectFileobj, // 媒体文件（视频或音频或图片），类型为 File
                mediaName: filename
            })
            that.uploader = uploader
            sessionStorage.setItem('selectFileobjData', selectFileobj)
            uploader.on('media_progress', function (info) {
                console.log(info.percent) // 进度
                that.percentage = parseInt(info.percent * 100);
            })
            uploader.done().then(function (res) {
                console.log(res)
                that.videoUrl = res.video.url
                that.resourceId = res.fileId
                if (that.contentId) {
                    that.saveResourceMethod(that.videoUrl)
                }
                that.releaseVideo(that.resourceId)
                sessionStorage.setItem('videoUri', res.video.url)
                that.$refs["videoUrl"].value = ''
                setTimeout(() => {
                    that.$message({
                        message: `上传成功，视频正在转码请稍后再播放`,
                        type: 'warning',
                    });
                }, 1000)
            }).catch(function (err) {
            })
        },
        async releaseVideo(id) {//发布视频
            const { data } = await weChatMiniProgramPublish({ fileId: id })
            if (data.code == 200) {
                // this.$message.success(data.msg)
            } else {
                this.$message.error(data.msg)
            }
        },
        goback() {
            // this.currentFileList = []
            this.contentList=[]
            this.navigationBarList = []
            this.parentId = 0
            this.currentFileList = this.warehousemap[this.parentId].children ? this.warehousemap[this.parentId] : []
        },
        clickTopTitleList(item) {//点击最顶层文件夹
            this.pushNavigation(item)//操作导航栏菜单
            this.setData(item)
        },
        clickNavigationList(item, index) {//点击导航菜单栏
            this.navigationBarList.splice(index + 1)//删除导航栏菜单
            this.setData(item)
        },
        enterFile(item) {//进入
            this.pushNavigation(item)//操作导航栏菜单
            this.setData(item)
        },
        setData(item) {
            this.currentFileList = this.warehousemap[item.id].children ? this.warehousemap[item.id] : []
            this.haveInfo = this.warehousemap[item.id].haveInfo
            this.parentId = this.warehousemap[item.id].id
            if (!this.haveInfo) {
                this.contentList = []
                return
            }
            this.getContentList()
        },
        pushNavigation(item) {//添加进导航栏
            let isHave = this.navigationBarList.some(i => { return i.id == item.id })
            if (isHave) return
            this.navigationBarList.push(item)
        },
        handleCommand(command) {
            console.log(command)
            if (command.type == 'a') {//编辑

                this.fileId = command.row.id
                this.name = command.row.name
                this.weight = command.row.weight
                this.parentId = command.row.parentId
                this.isValidity = command.row.isValidity ? true : false
                this.fileVisible = true

            } else if (command.type == 'b') {//删除
                console.log(command.row)
                let that = this
                this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    that.fileMethoddele(command.row.id)
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            }
        },
        composeValue(item, row) {
            return {
                type: item,
                row: row
            }
        },
        brandIdSelectBigChange(){
          this.brandId = this.brandIdSelectBig
            this.getWarehouseList()
        },
        async getWarehouseList() {//获取仓库列表
          if (sessionStorage.getItem('permissionRoleVoRoleId') && sessionStorage.getItem('permissionRoleVoRoleId') == 2) {
            this.brandIdSelectBigShow = true
            if (!this.brandIdSelectBig) {
              this.$message.warning('管理员请选择品牌')
              return
            }
          }

            try {
                const params = {
                    level: 100,
                    warehouseId: 0,
                    brandId: this.brandIdSelectBig? this.brandIdSelectBig : '',
                }
                const { data } = await getWarehouseApi(params)
                if (data.code == 200) {
                    this.warehouseObj = data.data
                    this.recursionWarehouseObj(this.warehouseObj)
                    console.log(data.data)
                    console.log(this.warehousemap)
                    this.currentFileList = this.warehousemap[this.parentId].children ? this.warehousemap[this.parentId] : []
                } else {
                    this.$message.error(data.msg)
                }
            } catch (e) {
                console.log(e)
                this.$message.error('请求异常')
            }

        },

        recursionWarehouseObj(arr) {//遍历数据
            this.warehousemap[arr.id] = arr
            if (!arr.children) return
            arr.children.map(item => {
                this.recursionWarehouseObj(item)
            })
        },
        async getContentList() {//获取内容列表
            try {
                const { data } = await getContentApi({ warehouseId: this.parentId, brandId: this.brandId })
                if (data.code == 200) {
                    if (!data.data && !this.isMove) {//没有返回数据就一直请求（增加删除，移动除外）
                        this.loading = true
                        setTimeout(() => {
                            this.getContentList()
                        }, 2000)
                        return
                    }
                    this.isMove=false
                    this.loading = false
                    this.contentVisible = false
                    if(!data.data){
                        this.originList = []
                        this.contentList = []
                        return
                    }
                    let contentList = data.data.map(item => {
                        let imgList = []
                        if (item.resources) {
                            item.resources.map(resourceItem => {
                                if (resourceItem.type == 1) {
                                    item.content = resourceItem
                                }
                                if (resourceItem.type == 2) {
                                    imgList.push(resourceItem)
                                }
                                if (resourceItem.type == 3) {
                                    item.video = resourceItem
                                }
                            })
                            item.imgList = imgList
                        }
                        return item
                    })
                    let originList = JSON.parse(JSON.stringify(contentList))
                    // originList.forEach(item => {
                    //     let brandList = []
                    //     if (item.info.brandBinarySum & 1) {
                    //         brandList.push(1)
                    //     }
                    //     if (item.info.brandBinarySum & 2) {
                    //         brandList.push(2)
                    //     }
                    //     if (item.info.brandBinarySum & 4) {
                    //         brandList.push(4)
                    //     }
                    //     if (item.info.brandBinarySum & 8) {
                    //         brandList.push(8)
                    //     }
                    //     item.brandList = brandList
                    // })
                    this.originList = originList
                    this.contentList = contentList
                } else {
                    this.$message.error(data.msg)
                }
            } catch (e) {
                console.log(e)
                // this.$message.error('请求异常')
            }

        },
        async getIdList() {//获取身份列表
            try {
                const { data } = await getTagApi()
                if (data.code == 200) {
                    this.idList = data.data
                } else {
                    this.$message.error(data.msg)
                }
            } catch (e) {
                console.log(e)
                this.$message.error('请求异常')
            }

        },
        addFileBtn() {//新增文件夹
            this.fileVisible = true
            this.fileId = ''
            this.name = ''
            this.weight = ''
            this.contentId = ''
        },
        reset() {
            this.brandIdSelect = ''
            this.tagSelect = ''
            this.name = ''
            this.weight = ''
            this.mediaType = '2'
            this.imgList = []
            this.resourceUrl = ''
            this.resourceId = ''
            this.content = ''
            this.showObj = {},
            this.deleteList = [],//删除列表
            this.addImgList = [],//新增的图片列表
            this.contentObj = {}
            this.contentId=''
        },
        async fileMethod() {//新增、编辑文件夹方法
            try {
                const params = {
                    name: this.name,
                    weight: this.weight,
                    parentId: this.parentId,
                    id: this.fileId,
                    isValidity: this.isValidity ? 1 : 0
                }
                if (this.fileId) {
                    const { data } = await saveWarehouseApi(params, 'put')
                    if (data.code == 200) {
                        this.$message.success(data.msg)
                        this.fileVisible = false
                        // this.reset()
                        setTimeout(() => {
                            this.getWarehouseList()
                        }, 100)
                    } else {
                        this.$message.error(data.msg)
                    }
                } else {
                    const { data } = await saveWarehouseApi(params, 'post')
                    if (data.code == 200) {
                        this.$message.success(data.msg)
                        this.fileVisible = false
                        // this.reset()
                        setTimeout(() => {
                            this.getWarehouseList()
                        }, 100)
                    } else {
                        this.$message.error(data.msg)
                    }
                }


            } catch (e) {
                console.log(e)
                this.$message.error('请求异常')
            }

        },
        async fileMethoddele(id) {//删除文件方法
            try {
                const { data } = await deleteWarehouseApi({ warehouseId: id })
                if (data.code == 200) {
                    this.$message.success(data.msg)
                    this.getWarehouseList()
                } else {
                    this.$message.error(data.msg)
                }
            } catch (e) {
                console.log(e)
                this.$message.error('请求异常')
            }

        },
        cancelFileMethod() {//取消文件夹
            this.fileVisible = false
        },
        addContentBtn() {//新增内容
            this.contentVisible = true
            this.reset()
        },
        editInfo(row) {//编辑按钮
            console.log(row)
            this.showObj = row
            this.contentVisible = true
            // let brandIdSelect = []
            // if ((row.info.brandBinarySum & 1) == 1) {
            //     brandIdSelect.push(1)
            // }
            // if ((row.info.brandBinarySum & 2) == 2) {
            //     brandIdSelect.push(2)
            // }
            // if ((row.info.brandBinarySum & 4) == 4) {
            //     brandIdSelect.push(4)
            // }
            // if ((row.info.brandBinarySum & 8) == 8) {
            //     brandIdSelect.push(8)
            // }
            this.tagSelect = row.info.tagId
            // this.brandIdSelect = brandIdSelect
            this.content = row.content ? row.content.content : ''
            this.contentObj = row.content ? row.content : ''
            this.contentId = row.info.id
            if (row.imgList.length > 0) {
                this.mediaType = '2'
                this.imgList = row.imgList
            } else if (row.video) {
                this.mediaType = '3'
                this.videoUrl = row.video.resourceUrl
            } else {
                this.mediaType = '2'
                this.imgList = []
                this.videoUrl = ''
                this.resourceId = ''
            }
        },
        contentMethod() {//新增、编辑内容方法
            if (this.contentId) {//编辑
                if (this.deleteList.length > 0) {
                    this.resourceMethod('delete')//删除资源
                }
                if (this.contentObj == '' && this.content) {//保存内容
                    this.updateResourceMethod('post')
                } else if ((this.contentObj.content != this.content) && this.contentObj.content) {//修改内容
                    this.updateResourceMethod('put')
                }
                this.contentEdit()//修改info
            } else {//新增
                this.contentAdd()
            }
        },
        async contentEdit() {//编辑
            // let brandBinarySum = 0
            // if(this.brandIdSelect.length<=0){
            //     this.$message.error('请选择品牌')
            //     return
            // }
            // this.brandIdSelect.map(item => {
            //     brandBinarySum += item
            // })
            if(!this.tagSelect){
                this.$message.error('请选择身份')
                return
            }
            const params = {
                id: this.contentId,
                // brandBinarySum,
                tagId: this.tagSelect,
                warehouseId: this.parentId,
            }
            const { data } = await materialInfoApi(params, 'put')
            if (data.code == 200) {
                this.$message.success(data.msg)
                this.contentVisible = false
                this.getContentList()
            } else {
                this.$message.error(data.msg)
            }
            console.log(params)
        },
        async contentAdd() {//添加
            // let brandBinarySum = 0
            // if(this.brandIdSelect.length<=0){
            //     this.$message.error('请选择品牌')
            //     return
            // }
            // this.brandIdSelect.map(item => {
            //     brandBinarySum += item
            // })
            if(!this.tagSelect){
                this.$message.error('请选择身份')
                return
            }
            let resourceList = []
            if (this.content) {//内容
                resourceList.push({
                    content: this.content,
                    materialWarehouseId: this.parentId,
                    type: 1
                })
            }
            if (this.mediaType == 2) {//图片
                if (!this.imgList) return
                let list = this.imgList.map(item => {
                    item.materialWarehouseId = this.parentId,
                        item.type = 2
                    return item
                })
                resourceList.push(...list)
            }

            if (this.mediaType == 3) {//视频
                resourceList.push({
                    resourceId: this.resourceId,
                    materialWarehouseId: this.parentId,
                    resourceUrl: this.videoUrl,
                    type: 3
                })
            }

            const params = {
                // brandBinarySum,
                tagId: this.tagSelect,
                warehouseId: this.parentId,
                resourceList
            }
            const { data } = await materialInfoApi(params, 'post')
            if (data.code == 200) {
                this.$message.success(data.msg)
                this.contentVisible = false
                setTimeout(()=>{
                    this.getContentList()
                },1000)
            } else {
                this.$message.error(data.msg)
            }
            console.log(params)
        },
        deleteMaterial(item) {//删除内容
            if (!item.info) {
                this.$message.error('数据格式错误,没有info')
                return
            }
            const params = {
                id: item.info.id,
                // brandBinarySum: item.info.brandBinarySum,
                tagId: item.info.tagId,
                warehouseId: item.info.wareHouseId,
                resourceList: item.resources
            }
            let that = this
            this.$confirm('此操作将永久删除该内容, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                that.deleteMaterialMethod(params)
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        async deleteMaterialMethod(params) {//删除内容
            try {
                const { data } = await materialInfoApi(params, 'delete')
                if (data.code == 200) {
                    this.$message.success(data.msg)
                    this.isMove=true
                    setTimeout(()=>{
                        this.getContentList()
                    },1000)
                } else {
                    this.$message.error(data.msg)
                }
            } catch (err) {
                console.log(err)
                this.$message.error(err)
            }
        },
        cancelContentMethod() {//取消内容
            this.contentVisible = false
        },
    },
}
</script>

<style lang="less" scoped>
.isValidityTrue {
    color: #fff;
    font-size: 12px;
    background: #52C41A;
    padding: 1px 3px;
    border-radius: 20px;
    margin-right: 5px;
}

.isValidityFalse {
    color: #fff;
    font-size: 12px;
    background: #FF4D4F;
    padding: 1px 3px;
    border-radius: 20px;
    margin-right: 5px;
}

/deep/.el-dropdown {
    display: flex;
}

/deep/.el-progress-circle {
    width: 104px !important;
    height: 104px !important;
}

.imgListWrap {
    display: flex;
    flex-wrap: wrap;
    position: relative;
}

.clearImg {
    position: absolute;
    top: 10px;
    right: 10px;
}

.imgShowClass {
    width: 104px;
    height: 104px;
    position: relative;
}

/deep/.el-radio__input.is-checked+.el-radio__label {
    color: #00A78E;
}

/deep/.el-radio__input.is-checked .el-radio__inner {
    border-color: #00A78E;
    background: #00A78E
}

.playSet {
    margin: 16px 0;
}

.myTitle {
    width: 80px;
    flex-shrink: 0;
    text-align: center;
}

.uploadInput {
    width: 104px;
    height: 104px;
    position: absolute;
    top: 0;
    right: 0;
    display: none;
}

.uploadDemo {
    display: flex;
    position: relative;
}

.item {
    display: flex;
    align-items: center;
    margin: 8px 0;

    /deep/.long .el-input__inner {
        width: 264px;
        height: 32px;
    }

    /deep/.short .el-input__inner {
        width: 160px;
        height: 32px;
    }

    /deep/.mid .el-input__inner {
        width: 104px;
        height: 32px;
    }

    /deep/.short .el-input__suffix {
        line-height: 50px;
    }
}

// .my_inpuf{
//     width: 100%;
// }
/deep/.my_inpuf .el-input__inner {
    // width: 96px;
    width: 100%;
    height: 24px;
    line-height: 24px;
    border-radius: 0;
}

/deep/.my_inpuf .el-input__icon {
    line-height: 24px;
}

.gridLayout {
    display: grid;
    grid-template-columns: 3fr 2fr;
}

.display {
    display: flex;
    align-items: center;
}

.fs0 {
    flex-shrink: 0;
}

.f14 {
    font-size: 14px;
}

.titleLine {
    width: 8px;
    height: 20px;
    background: #00A78E;
    border-radius: 67px;
    margin-right: 10px;
}

.goback {
    color: #3C4645;
    font-size: 24px;
}

.navigationList {
    display: flex;
    align-items: center;
    // overflow: auto;
}

.navigationItem {
    font-size: 24px;
}

.navigationName {
    font-family: PingFang SC Bold;
    color: #ACB6B5;
}

.navigationNameCurrent {
    font-family: PingFang SC Bold;
    color: #3C4645;
}

.main {
    padding: 12px;
    display: flex;
    flex: 1%;
    flex-direction: column;
    box-sizing: border-box;
}

.topTitlesTrue {

    display: flex;
    // justify-content: space-between;
    margin-bottom: 24px;
}

.topTitles {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
}

.mainContent {
    margin: 10px 0;
    display: flex;
    flex: 1;
}

.menuFile {
    width: 50%;
    flex: 1;
    background: #fff;
    margin-right: 20px;
    border-radius: 8px;
    padding: 24px;
    box-sizing: border-box;
}

.menuContent {
    width: 50%;
    flex: 1;
    background: #fff;
    border-radius: 8px;
    padding: 24px;
    box-sizing: border-box;
}

.title {
    font-size: 24px;
    color: #3C4645;
}

.fileManagement {
    font-size: 20px;
    color: #3D3D3D;
    font-weight: bold;
    display: flex;
    align-items: center;
}

.addFile {
    width: 96px;
    height: 32px;
    background: #00A78E;
    color: #fff;
    font-size: 14px;
    line-height: 32px;
    text-align: center;
}

.addBtn {
    width: 96px;
    height: 32px;
    // border: 1px solid #00A78E;
    color: #1890FF;
    font-size: 14px;
    line-height: 40px;
    text-align: center;
}

.drag,
.more {
    width: 24px;
    height: 24px;
}

.menuList {
    display: flex;
    align-items: center;
    // margin: 24px 0 35px 0;
}

.menuItem {
    width: 136px;
    height: 40px;
    display: flex;
    align-items: center;
    background: #fff;
    justify-content: center;
    padding: 8px;
    box-sizing: border-box;
    align-items: center;
    border: 1px solid #00A78E;
    margin: 0 10px;
    border-radius: 4px;
    color: #00A78E;
}

.titleName {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: 14px;
    width: 60%;
    text-align: center;
    font-family: Source Han Sans CN;
}

.icons {
    display: flex;
    align-items: center;
}

.tableMenu {
    display: flex;
    align-items: center;
    justify-content: center;
}

.operationStatus {
    margin: 0 10px;
}
</style>
