import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "../store/index.js"


Vue.use(VueRouter)

const routes = [

  {
    path: '/',
    name: 'index',
    component: () => import('../views/index.vue'),
    redirect:'/login',
    children:[
      {
        path: '/agentHome',
        name: 'agentHome',
        component: () => import('../views/agentHome.vue'),
      },
      {
        path: '/manageHome',
        name: 'manageHome',
        component: () => import('../views/manageHome.vue'),
      },
      {
        path: '/moduleHits',
        name: 'moduleHits',
        component: () => import('../views/moduleHits/index.vue'),
      },
      {
        path: '/answerQuestions',
        name: 'answerQuestions',
        component: () => import('../views/answerQuestions/index.vue'),
      },
      {
        path: '/songGuoRules',
        name: 'songGuoRules',
        component: () => import('../views/songGuoRules/index.vue'),
      },
      {
      path: '/home',
      name: 'home',
      component: () => import('../views/home.vue'),
    },
    {//菜单
      path: '/menuTree',
      name: 'menuTree',
      component: () => import('../views/menu/menuTree.vue'),
    },
    {//父菜单
      path: '/menuParent',
      name: 'menuParent',
      component: () => import('../views/menu/menuParent.vue'),
    },
     {//子菜单
      path: '/menuChild',
      name: 'menuChild',
      component: () => import('../views/menu/menuChild.vue'),
    },
     {//添加菜单
      path: '/menuAdd',
      name: 'menuAdd',
      component: () => import('../views/menu/menuAdd.vue'),
    },
     {//订单列表
      path: '/orderList',
      name: 'orderList',
      component: () => import('../views/mall/orderList.vue'),
    },
      {//退款订单列表
        path: '/refundOrderList',
        name: 'refundOrderList',
        component: () => import('../views/mall/refundOrderList.vue'),
      },
    {//商品分类
      path: '/mallClass',
      name: 'mallClass',
      component: () => import('../views/mall/mallClass.vue'),
    },
     {//商品分类列表
      path: '/mallClassList',
      name: 'mallClassList',
      component: () => import('../views/mall/mallClassList.vue'),
    },
    {//课程分类
      path: '/courseClass',
      name: 'courseClass',
      component: () => import('../views/course/courseClass.vue'),
    },
     {//续费管理
      path: '/renewPay',
      name: 'renewPay',
      component: () => import('../views/course/renewPay.vue'),
    },
    {//新增编辑课程
      path: '/courseedit',
      name: 'courseedit',
      component: () => import('../views/course/courseedit.vue'),
    },
     {//意见反馈列表
      path: '/opinionList',
      name: 'opinionList',
      component: () => import('../views/mall/opinionList.vue'),
    },
      {//试题反馈
        path: '/itemFeedBackList',
        name: 'itemFeedBackList',
        component: () => import('../views/mall/itemFeedBackList.vue'),
      },
     {//商品列表
      path: '/mallList',
      name: 'mallList',
      component: () => import('../views/mall/mallList.vue'),
    },
     {//商品列表
      path: '/mallAdd',
      name: 'mallAdd',
      component: () => import('../views/mall/mallAdd.vue'),
    },
    //考试管理
    {
      path: '/textIndex',
      name: 'textIndex',
      component: () => import('../views/exammanagement/textIndex.vue'),
    },
    {
      path: '/textSetting',
      name: 'textSetting',
      component: () => import('../views/exammanagement/textSetting.vue'),
    },
    {
      path: '/peopleDetails',
      name: 'peopleDetails',
      component: () => import('../views/exammanagement/peopleDetails.vue'),
    },
    {
      path: '/text',
      name: 'text',
      component: () => import('../views/exammanagement/text.vue'),
    },
    //题库管理
    {
      path: '/exercises',
      name: 'exercises',
      component: () => import('../views/Subjectmanagement/exercises.vue'),
      // meta:{
      //   keepAlive:true
      // },
    },
    {//美容师首页
      path: '/beautician',
      name: 'beautician',
      component: () => import('../views/Subjectmanagement/beautician.vue')
    },
    {//搜索页面
      path: '/searchs',
      name: 'searchs',
      component: () => import('../views/Subjectmanagement/searchs.vue')
    },
    {//搜索详情页面
      path: '/searchsDes',
      name: 'searchsDes',
      component: () => import('../views/Subjectmanagement/searchsDes.vue')
    },
     {//美容师详情页
      path: '/beauticiandetails',
      name: 'beauticiandetails',
      component: () => import('../views/Subjectmanagement/beauticiandetails.vue')
    },
     {//代理商美容师详情页
      path: '/beauticiandetails2',
      name: 'beauticiandetails2',
      component: () => import('../views/Subjectmanagement/beauticiandetails2.vue')
    },
    {//调查问卷管理
      path: '/surveyPage',
      name: 'surveyPage',
      component: () => import('../views/Subjectmanagement/surveyPage.vue'),
    },
    {//查看调查问卷详情
      path: '/surveyPapgeDetails',
      name: 'surveyPapgeDetails',
      component: () => import('../views/Subjectmanagement/surveyPapgeDetails.vue'),
    },
    {//查看调查问卷详情
      path: '/surveyPapgeDetails2',
      name: 'surveyPapgeDetails2',
      component: () => import('../views/Subjectmanagement/surveyPapgeDetails2.vue'),
    },
    {//添加调查问卷
      path: '/surveyPapgeAdd',
      name: 'surveyPapgeAdd',
      component: () => import('../views/Subjectmanagement/surveyPapgeAdd.vue'),
    },
    {//添加调查问卷试题
      path: '/surveyPapgeAdd2',
      name: 'surveyPapgeAdd2',
      component: () => import('../views/Subjectmanagement/surveyPapgeAdd2.vue'),
    },
    {
      path: '/exercisesAdd',
      name: 'exercisesAdd',
      component: () => import('../views/Subjectmanagement/exercisesAdd.vue'),
    },
    {
      path: '/exercisesAdd2',
      name: 'exercisesAdd2',
      component: () => import('../views/Subjectmanagement/exercisesAdd2.vue'),
    },

    {//课程包列表
      path: '/courseList',
      name: 'courseList',
      component: () => import('../views/contentmanagement/courseList.vue'),
    },
    {//音频课程
      path: '/videoCourse',
      name: 'videoCourse',
      component: () => import('../views/course/videoCourse.vue'),
    },
    {//社区管理
      path: '/community',
      name: 'community',
      component: () => import('../views/course/community.vue'),
    },
    {//课程反馈
      path: '/courseFeedback',
      name: 'courseFeedback',
      component: () => import('../views/course/courseFeedback.vue'),
    },
    {//课程包列表（新）
      path: '/coursePageList',
      name: 'coursePageList',
      component: () => import('../views/course/coursePageList.vue'),
    },
    {//推荐管理
      path: '/recommendedList',
      name: 'recommendedList',
      component: () => import('../views/course/recommendedList.vue'),
      // meta:{
      //   keepAlive:true
      // }
    },
    {//课程包列表（新）
      path: '/coursePageList2',
      name: 'coursePageList2',
      component: () => import('../views/course/coursePageList2.vue'),
      // meta:{
      //   keepAlive:true
      // }
    },
    {//课程包列表（新）
      path: '/coursePageList3',
      name: 'coursePageList3',
      component: () => import('../views/course/coursePageList3.vue'),
      // meta:{
      //   keepAlive:true
      // }
    },
    {//课程包列表(父)
      path: '/courseParentAdd',
      name: 'courseParentAdd',
      component: () => import('../views/course/courseParentAdd.vue'),
    },
    {//课程包列表(父-子)
      path: '/courseParentAdd2',
      name: 'courseParentAdd2',
      component: () => import('../views/course/courseParentAdd2.vue'),
    },
    {//课程包列表(父-子)
      path: '/courseParentAdd3',
      name: 'courseParentAdd3',
      component: () => import('../views/course/courseParentAdd3.vue'),
    },
    {//课程包列表(子)
      path: '/courseChildAdd',
      name: 'courseChildAdd',
      component: () => import('../views/course/courseChildAdd.vue'),
    },
    {//课程包列表(子)
      path: '/courseChildAdd2',
      name: 'courseChildAdd2',
      component: () => import('../views/course/courseChildAdd2.vue'),
    },
    {//课程包列表(编辑专用)
      path: '/courseChildAdd3',
      name: 'courseChildAdd3',
      component: () => import('../views/course/courseChildAdd3.vue'),
    },
     {//课程内容添加
      path: '/courseContent',
      name: 'courseContent',
      component: () => import('../views/course/courseContent.vue'),
    },
    {//课程内容更新
      path: '/courseContent2',
      name: 'courseContent2',
      component: () => import('../views/course/courseContent2.vue'),
    },
    {//课程包添加修改
      path: '/courseAdd',
      name: 'courseAdd',
      component: () => import('../views/contentmanagement/courseAdd.vue'),
    },
    {//课程管理
      path: '/courseManage',
      name: 'courseManage',
      component: () => import('../views/contentmanagement/courseManage.vue'),
    },

    {//内容管理
      path: '/content',
      name: 'content',
      component: () => import('../views/contentmanagement/content.vue'),
    },
     {//课程包内容管理
      path: '/courseContent',
      name: 'courseContent',
      component: () => import('../views/contentmanagement/courseContent.vue'),
    },
    {
      path: '/contentAdd',
      name: 'contentAdd',
      component: () => import('../views/contentmanagement/contentAdd.vue'),
    },
    {
      path: '/videoAdd',
      name: 'videoAdd',
      component: () => import('../views/contentmanagement/videoAdd.vue'),
    },
     //人员管理
     {
      path: '/people',
      name: 'people',
      component: () => import('../views/peoplemanagement/people.vue'),
    },
    {
      path: '/peopleList',
      name: 'peopleList',
      component: () => import('../views/peoplemanagement/peopleList.vue'),
    },
    //助理专用人员管理
    {
      path: '/Assistant',
      name: 'Assistant',
      component: () => import('../views/peoplemanagement/Assistant.vue'),
    },
    //门店管理
    {
      path: '/store',
      name: 'store',
      component: () => import('../views/storemanagement/store.vue'),
    },
     //导师端-考试管理
     {
      path: '/markingList',
      name: 'markingList',
      component: () => import('../views/markingmanagement/markingList.vue'),
      // meta:{
      //   keepAlive:true
      // }
    },
    {
      path: '/marking',
      name: 'marking',
      component: () => import('../views/markingmanagement/marking.vue'),
    },
    {
      path: '/marking2',
      name: 'marking2',
      component: () => import('../views/markingmanagement/marking2.vue'),
    },
    //学员管理
    {
      path: '/student',
      name: 'student',
      component: () => import('../views/studentmanagement/student.vue'),
    },
     //学员分配
     {
      path: '/distribution',
      name: 'distribution',
      component: () => import('../views/distributionmanagement/distribution.vue'),
    },
     //审核端-审核
     {
      path: '/audit',
      name: 'audit',
      component: () => import('../views/auditmanagement/audit.vue'),
    },
    {
      path: 'buyVip',
      name: 'buyVip',
      component: () => import('../views/buyVip.vue'),
    },
    {
      path: '/foraudit/:id',
      name: 'foraudit',
      component: () => import('../views/auditmanagement/foraudit.vue'),
    },
    {//推荐管理
      path: '/recommendPage',
      name: 'recommendPage',
      component: () => import('../views/recommendPackage/recommendPage.vue'),
    },
    {//推荐管理
      path: '/idManagement',
      name: 'idManagement',
      component: () => import('../views/recommendPackage/idManagement.vue'),
    },
  ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login.vue')
  },

]

const router = new VueRouter({
  mode: 'history',
  // base: process.env.BASE_URL,
  // base:'trainee',
  routes
})

// router.beforeEach((to, from,next) => {
//   console.log(to.fullPath)
//   // if(to.fullPath=='/agentHome'){//判断是否首页取消返回按钮
//   //   sessionStorage.setItem('homePage',true)
//   // }else{
//   //   sessionStorage.setItem('homePage',false)
//   // }
//   // ...
//   // 返回 false 以取消导航
//   // return false
//   next()
// })

// router.beforeEach((to, from,next) => {//用于页面权限拦截重置
//   console.log(to)
//   if(to.path=='/login'){
//     next()
//     return true
//   }else{
//     if(to.path!='/login' && sessionStorage.getItem('token')==''){
//       location.href='/login'
//       return
//     }
//     if(sessionStorage.getItem('permissions')!='undefined' && sessionStorage.getItem('permissions')!=""){

//       let permissions=JSON.parse(sessionStorage.getItem('permissions'))
//      let res=permissions.filter(item=>{
//         if(item.uri){
//           return item
//         }
//       })
//      let uriList=res.map(item=>{
//         return item.uri
//       })
//      let res2=permissions.filter(item=>{
//         return item.children
//       })
//      let res2Total=res2.map(item=>{
//         return item.children
//       })
//       let uriList2=[]
//       res2Total.map(item=>{
//         item.map(item2=>{
//           uriList2.push(item2.uri)
//         })
//       })
//       let list2=[...uriList,...uriList2]
//      let index=list2.findIndex(item=>{
//         return item==to.path
//       })
//       if(index==-1 && sessionStorage.getItem('token')){//没有权限但已登录
//           location.href='/home'
//       }else if(index==-1 && sessionStorage.getItem('token')==''){//没有权限且未登录
//         location.href='/login'
//       }
//     }
//     next()
//     return true
//   }


// })

export default router
