<!-- 题库管理首页 -->
<template>
  <div>
    <div class="main">
      <div style="font-size: 26px; font-weight: bold">试题反馈</div>
      <div>
        <div>
        </div>
        <div>

        </div>
      </div>

      <el-table
        :header-cell-style="{ background: '#F7F7F7' }"
        :data="tableData"
        style="width: 100%; min-height: 600px"
         @selection-change="handleSelectionChange"
      >
      <el-table-column
      type="selection"
      align='center'
      width="55"/>
        <el-table-column type="index" width="50"> </el-table-column>
        <el-table-column prop="userName" align="center" label="用户名" :show-overflow-tooltip="true" >
        </el-table-column>
        <el-table-column prop="ctime" align="center" label="反馈时间" :show-overflow-tooltip="true" >
        </el-table-column>
         <el-table-column prop="utime" align="center" label="解决时间" :show-overflow-tooltip="true" >
        </el-table-column>
        <el-table-column prop="brandId" align="center" label="品牌" :show-overflow-tooltip="true" >
          <template slot-scope="scope">
            {{
              scope.row.questionInfo ? scope.row.questionInfo.brandId ? getBrandName(scope.row.questionInfo.brandId) : '' : ''
            }}
          </template>
        </el-table-column>
        <el-table-column prop="categoryName" align="center" label="分类名称" :show-overflow-tooltip="true" >
          <template slot-scope="scope">
            {{ scope.row.courseInfo?scope.row.courseInfo.categoryName?scope.row.courseInfo.categoryName:'':'' }}
          </template>
        </el-table-column>
        <el-table-column prop="name" label="课程包名称" align="center" >
          <template slot-scope="scope">
            <div>
              {{ scope.row.courseInfo?scope.row.courseInfo.name?scope.row.courseInfo.name:'':'' }}
            </div>
          </template>
        </el-table-column>
         <el-table-column prop="questionInfo" label="题目" align="center">
           <template slot-scope="scope">
             <div>
               {{ scope.row.questionInfo ? scope.row.questionInfo.content ? scope.row.questionInfo.content : '' : '' }}
             </div>
           </template>
         </el-table-column>
        <el-table-column prop="des" align="center" label="反馈类型" :show-overflow-tooltip="true" >
          <template slot-scope="scope">
            <div>
              {{scope.row.type==4?scope.row.topicType==1?'题目':scope.row.topicType==2?'答案':'其它':''}}
            </div>
          </template>
        </el-table-column>
         <el-table-column prop="feedbackContent" label="反馈内容" align="center" max-width="200"  :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="address" label="操作" align="center" width="200">
          <template slot-scope="scope">
            <div style="display: flex; justify-content: center">
             <el-link type="primary" :underline="false" @click='deal(scope.row)' style="margin:0 20px" v-if="scope.row.processState!=1 && scope.row.processState!=9">处理</el-link>
              <el-link type="primary" :underline="false" v-else @click='detailsInfo(scope.row)' >查看详情</el-link>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: flex-end;
        "
      >
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageIndex"
          :page-sizes="[10, 15, 20]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>

  <el-dialog
  title="处理" :visible.sync="dialogVisible" width="80%" :before-close="handleClose" :close-on-click-modal="false">
    <el-form :model="form" label-width="100px"  ref="form">
      <el-row class="dialog-el-row">
        <el-col :span="6">
          <div>用户反馈</div>
        </el-col>
        <el-col :span="18" style="display: flex;flex-direction: column">
          <p style="margin-top: 20px"><span>用户名称：</span><span>{{form.userName}}</span></p>
          <p style="margin-top: 20px"><span>针对模块：</span><span>{{form.topicType==1?'题目':form.topicType==2?'答案':'其它'}}</span></p>
          <p style="margin-top: 20px"><span>反馈内容：</span><span>{{form.feedbackContent}}</span></p>
          <p style="margin-top: 20px"><span>反馈时间：</span><span>{{form.utime}}</span></p>
        </el-col>
      </el-row>
      <el-row class="dialog-el-row">
        <el-col :span="6">
          <div>反馈是否有效 <span style="color: red">*</span></div>
        </el-col>
        <el-col :span="18">
          <el-radio-group v-model="form.processState" prop="processState">
            <el-radio :label="9">有效</el-radio>
            <el-radio :label="1">无效</el-radio>
          </el-radio-group>
        </el-col>
      </el-row>
      <el-row class="dialog-el-row">
        <el-col :span="6">
          <div>反馈处理备注<span style="color: red">*</span>：</div>
        </el-col>
        <el-col :span="18">
          <el-input v-model="form.processContent" type="textarea" placeholder="请输入内容"></el-input>
        </el-col>
      </el-row>
      <el-row class="dialog-el-row">
        <el-col :span="6" style="font-size: 20px;font-weight: bold">
          试题内容
        </el-col>
      </el-row>
      <el-row class="dialog-el-row">
        <el-col :span="6">
          <div>层级设置：</div>
        </el-col>
        <el-col :span="18">
          <el-radio v-model="levelId" label="1">lv1</el-radio>
          <el-radio v-model="levelId" label="2">lv2</el-radio>
          <el-radio v-model="levelId" label="3">lv3</el-radio>
          <el-radio v-model="levelId" label="4">lv4</el-radio>
        </el-col>
      </el-row>
      <el-row class="dialog-el-row">
        <el-col :span="6">
          <div>题目类型：</div>
        </el-col>
        <el-col :span="18">
            <el-radio v-model="typeId" label="1">选择题</el-radio>
            <el-radio v-model="typeId" label="2">判断题</el-radio>
        </el-col>
      </el-row>
      <el-row class="dialog-el-row">
        <el-col :span="6">
          <div>题干：</div>
        </el-col>
        <el-col :span="18">
          <el-input v-model="content" type="textarea" placeholder="请输入内容"></el-input>
        </el-col>
      </el-row>
      <el-row class="dialog-el-row">
        <el-col :span="6">
          <div>答案设置：</div>
        </el-col>
      </el-row>
      <el-row class="dialog-el-row">
        <el-col :span="6">
          <div style="flex-direction: column">
            <el-checkbox-group v-model="checkList" style="display: flex; flex-direction: column"
                               v-if="typeId == 1 || typeId == ''">
              <div style="display: flex; margin-top: 26px; align-items: center" v-for="(item, index) of selectData"
                   :key="index">
                <el-checkbox :label="item.label" style="display: flex; align-items: center"
                             @change="((val) => { sel(val, index) })">
                  <div style="display: flex; align-items: center">
                    <div style="margin: 0 13px 0 27px">{{ item.name }}</div>
                    <el-input v-model="item.value" placeholder="请填入选项内容" style="width: 290px"></el-input>
                  </div>
                </el-checkbox>
                <img v-if="index + 1 == selectData.length && index > 2" src="../../images/reduce.png" alt=""
                     style="width: 22px; height: 23px; margin-left: 10px" @click="deleIndex(index)" />
              </div>
              <div style="margin-top: 12px; margin-left: 200px">
                <img src="../../images/add.png" alt="" style="width: 22px; height: 23px" @click="add"
                     v-if='selectData.length < 6' />
              </div>
            </el-checkbox-group>

            <div style="display: flex; margin-top: 26px; flex-direction: column;font-weight: normal"
                 v-for="(item, index) of selectData" v-if="typeId == 3" :key="index">
              <div style="align-items:center">
                <div style="margin: 0 13px 0 27px">{{ item.name }}</div>
                <el-input v-model="item.value" placeholder="请填入选项内容" style="width: 290px"></el-input>
                <img v-if="index + 1 == selectData.length && index > 0" src="../../images/reduce.png" alt=""
                     style="width: 22px; height: 23px; margin-left: 10px" @click="deleIndex(index)" />
              </div>
              <div style="margin-top: 12px; margin-left: 200px" v-if="index + 1 == selectData.length && index < 5">
                <img src="../../images/add.png" alt="" style="width: 22px; height: 23px" @click="add" />
              </div>
            </div>
            <div style="display: flex; margin-top: 26px; align-items: center" v-for="(item, index) of selectData"
                 v-if="typeId == 2" :key="index">
              <el-radio v-model="radioAnsWer" :label="item.label" style="display: flex; align-items: center">
                <div style="margin: 0 13px 0 27px">{{ item.name }}</div>
              </el-radio>
              <img v-if="index + 1 == selectData.length && index > 2" src="../../images/reduce.png" alt=""
                   style="width: 22px; height: 23px" @click="deleIndex(index)" />
            </div>
          </div>
        </el-col>
      </el-row>
    </el-form>

  <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="submit" v-if="this.tempProcessState!=1 && this.tempProcessState!=9">确 定</el-button>
<!--    <el-button @click="dialogVisible = false" v-if="form.processState!=1 && form.processState!=9">取 消</el-button>-->
<!--    <el-button type="primary" @click="submit" v-if="form.processState!=1 && form.processState!=9">确 定</el-button>-->
  </span>
</el-dialog>

  </div>
</template>

<script>
import {
  questiontypeList,
  qsList,
  levelList,
  qssaveOrUpdate,
  PaperList,
  delePaper,
  goodsClassList,
  deleGoodsClass,
  getOpinion,
  updatefeedback,
  qssaveOrUpdate2, addPoint, checkPointSum
} from "../../apis/index";
export default {
  data() {
    return {
      radioAnsWer: '',//判断题选择
      checkList: [],
      tempProcessState:'',
      selectData: [
        {
          label: "1",
          name: "A",
          value: "",
        },
        {
          label: "2",
          name: "B",
          value: "",
        },
        {
          label: "3",
          name: "C",
          value: "",
        },
      ],
      dialogVisible:false,
      resourceUriDes:'',
      resourceUri:[],
      levelId:'',
      content:'',
      date:'',
      timeState:'',
      typeId: '1',//题目类型
      sellevelId: '',//筛选用的
      levelList: [//层级列表
        {
          name: 'lv1',
          id: 1
        },
        {
          name: 'lv2',
          id: 2
        },
        {
          name: 'lv3',
          id: 3
        },
      ],
      timeStateList:[
        {
          id:1,
          name:'未生效'
        },
        {
          id:2,
          name:'已生效'
        },
        {
          id:3,
          name:'已过期'
        },
      ],
       typeList:[
                 {
                    id:1,
                    name:'小程序'
                },{
                    id:2,
                    name:'课程包'
                }
            ],
            type:'',//问卷类型
      pageIndex: 1,
      pageSize: 10,
      total:0,
      qslist:[],
      levelLists:[],
      value: "",
      isshow: false,
      isshow2: true,
      isdisabled:true,
      currentPage: 1,
      radio: "1",
      input: "",
      tableData: [

      ],
      brandList: [],
      form: {
        questionInfo: '',
        processContent: '',
        processState: 0
      }
    };
  },
  watch: {
    typeId(newdata, olddate) {//添加试题类型监听
      console.log(newdata);
      if (Number(newdata) == 1) {
        this.selectData = [
          {
            label: "1",
            name: "A",
            value: "",
          },
          {
            label: "2",
            name: "B",
            value: "",
          },
          {
            label: "3",
            name: "C",
            value: "",
          },
          {
            label: "4",
            name: "d",
            value: "",
          },
        ];
      } else if (Number(newdata) == 2) {
        this.selectData = [
          {
            label: "1",
            name: "正确",
          },
          {
            label: "2",
            name: "错误",
          },
        ];
      }
    },
    coursePageName(newdata, olddata) {
      if (newdata == '') {
        this.getcourseList()
      } else {
        let tableData = this.tableData
        let tableDataNew = tableData.filter(item => {
          return item.record.name.indexOf(newdata) != -1
        })
        this.tableData = tableDataNew
      }
    },
    brandId() {
      this.getCateGorylist().then(res => {
        this.recordId = this.categoryId = this.cateGoryList[0].record.id
        this.getcourseList()
      })
    },
    configValue1(newdata, olddata) {
      if (newdata != 4) {
        this.priceMoney = this.pricePoint = 0
      }

    },
    questionStr(newdata, olddata) {
      this.pageIndex = 1
      console.log(newdata);
      if (newdata) {
        this.isshow = true;
      } else {
        this.questionStr = ''
        this.getQuestionList()
        this.isshow = false;
      }
    },
    dialogVisibleQuestion(newdata) {//监听试题管理关闭重置字段
      if (!newdata) {
        this.questionStr = ''
        this.questionId = ''
      }
    },
    questionId(newdata, olddata) {//监听切换题目类型
      this.questionId = newdata
      this.pageIndex = 1
      this.getQuestionList()
      // if (newdata == "2") {
      //   this.isshow2 = true;
      // } else {
      //   this.isshow2 = false;
      // }
    },
    input(newdata, olddata) {
     if(newdata==''){
        this.isshow=false
       this.input=''
       this.list()
     }else{
       this.isshow=true
     }
    },
    radio(newdata, olddata) {
     this.list()
    },
    value(){
      this.list()
    },
  },
  created(){
    // let date=new Date().toLocaleString( )
    // let date1=date.replace(/\//g,'-')
    // this.date=new Date(date1).getTime()
    // this.questype()
    this.list()
    this.brandList = this.$store.state.brandList
    console.log('this.brandList',this.brandList);
    // this.leave()
  },
  //   beforeRouteLeave(to, from, next) {
  //   if(to.path=='/exercisesAdd'){//添加页，就不缓存
  //     this.$route.meta.keepAlive=false
  //     setTimeout(() => {
  //       next()
  //     }, 100);
  //   }else{//详情页缓存
  //    this.$route.meta.keepAlive=true
  //     setTimeout(() => {
  //       next()
  //     }, 100);
  //   }
  //   // 导航离开该组件的对应路由时调用
  //   // 可以访问组件实例 `this`
  // },
  filters:{
    filtersDate(date){
     return new Date(date).getTime()
    },
  },
  methods: {
    selectQues(item) {
      this.sellevelId = item.id
      // this.getQuestionList()
    },
     deal(row) {
       this.dialogVisible = true
       this.form=row
       this.tempProcessState = row.processState
       this.questionDetails(row.questionInfo)
       this.typeId = String(row.questionInfo.typeId)
       this.levelId = String(row.questionInfo.levelId)
       this.content = String(row.questionInfo.content)
     },
    detailsInfo(row) {
      this.dialogVisible = true
      this.form=row
      this.tempProcessState = row.processState
      this.questionDetails(row.questionInfo)
      this.typeId = String(row.questionInfo.typeId)
      this.levelId = String(row.questionInfo.levelId)
      this.content = String(row.questionInfo.content)
    },
    look(item){//查看统计
    console.log(item)
    let items=JSON.stringify(item)
      this.$router.push({path:'/surveyPapgeDetails2',query:{items}})
    },
   async leave() {
      //获取等级列表
      const { data } = await levelList({roleId:3});
      if (data.code == 200) {
        this.levelLists = data.data;
      } else {
        this.$message.error(data.msg);
      }
    },
   async list(){
      const params={
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        levelId:this.levelId,
        type: 4
        // timeState:this.timeState,
        // type:1
      }
     const {data}=await getOpinion(params)
     if(data.code==200){
      //  let list=data.data.records.map(item=>{
      //    item.gettime=new Date(item.startTime).getTime()
      //    return item
      //  })
       this.tableData=data.data.records
       this.total=Number(data.data.total)
     }
    },
     async questype() {//题目类型列表
      const { data } = await questiontypeList();
      let qslist=data.data
      this.qslist = qslist;

    },
    add() {
      if (!this.typeId) {
        this.$message.error('请先选择题目类型')
        return
      }
      //添加
      if (this.typeId == 1) {
        let selectData = this.selectData;
        let res = selectData.some((item) => {
          return item.name == "D";
        });
        if (res) {
          let res = selectData.some((item) => {
            return item.name == "E";
          });
          if (res) {
            let res = selectData.some((item) => {
              return item.name == "E";
            });
            if (res) {
              let res = selectData.some((item) => {
                return item.name == "F";
              });
              if (!res) {
                this.selectData.push({
                  label: "6",
                  name: "F",
                  value: "",
                });
              }
            }
          } else {
            this.selectData.push({
              label: "5",
              name: "E",
              value: "",
            });

            console.log(this.selectData);
          }
        } else {
          this.selectData.push({
            label: "4",
            name: "D",
            value: "",
          });
          console.log(this.selectData);
        }
      }
    },
    handleClose(){
      this.dialogVisible=false
    },
    des(item){
      this.resourceUriDes=item
    },
     details2(row){//添加试题
      this.$router.push({path:'/surveyPapgeAdd2',query:{row:JSON.stringify(row)}})
    },
    search() {
      console.log("我是搜索");
    },
     handleSelectionChange(val) {//选中
        console.log(val)
        if(val.length>0){
          this.isdisabled=false
        }else{
          this.isdisabled=true
        }
      },
    handleSizeChange(val) {
      this.pageSize=val
      console.log(`每页 ${val} 条`);
      this.list()
    },
    handleCurrentChange(val) {
      this.pageIndex=val
      console.log(`当前页: ${val}`);
      this.list()
    },
     dele(row) {
        const params={
         id:row.id,
       }
        this.$confirm('此操作将永久删除该项, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
           deleGoodsClass(params).then(res=>{
            if(res.data.code==200){
              this.$message({
                type: 'success',
                message: '删除成功!'
              });
              this.list()
            }else{
              this.$message({
                type: 'error',
                message: res.data.msg
              });
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      },
       deleall() {
        this.$confirm('此操作将永久删除选中项, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      },
    getBrandName(brandId) {
      const brand = this.brandList.find(item => item.id === brandId);
      return brand ? brand.name : '未知品牌';
    },
    sel(val, index) {
      this.selectData[index].checked = val
      let obj = {
        checked: val,
        label: this.selectData[index].label,
        name: this.selectData[index].name,
        value: this.selectData[index].value,
      }
      this.$set(this.selectData, index, obj)
    },
    deleIndex(ind) {
      this.selectData.splice(ind, 1);
    },
    questionDetails(details) {//查看试题详情
      this.dialogVisible = true
      console.log(details)
      this.courseId = details.courseId
      if (details.typeId == 1) {//选择题
        this.typeId = String(details.typeId)
        // this.levelId=details.levelId
        this.content = details.content
        let answer = details.answer
        let arr = answer.split('&&&')
        let obj = arr.map((item, index) => {
          return {
            label: index,
            name: item.slice(0, 1),
            value: item.slice(1)
          }
        })
        this.selectData = obj
        let rightKey = details.rightKey
        let arr2 = rightKey.split('')
        let selectData = this.selectData
        arr2.map((item, index) => {
          selectData.forEach((item2, index2) => {
            if (index == index2 && item == 1) {
              item2.checked = true
            }
          })
        })
        this.editQuestionId = details.id
        this.selectData = selectData
        this.checkList = selectData.filter(item => item.checked).map(item => item.label)
        console.log(selectData)
      } else if (details.typeId == 2) {//判断题
        this.typeId = String(details.typeId)
        // this.levelId=details.levelId
        this.content = details.content

        this.selectData = this.selectData = [
          {
            label: "1",
            name: "正确",
          },
          {
            label: "2",
            name: "错误",
          },
        ];
        let selectData = this.selectData
        this.editQuestionId = details.id
        let rightKey = details.rightKey
        let arr2 = rightKey.split('')
        arr2.map((item, index) => {
          selectData.forEach((item2, index2) => {
            if (index == index2 && item == 1) {
              this.radioAnsWer = item2.label
            }
          })
        })
      }

    },
    async submit() {
      if (!this.form.processState) {
        this.$message.warning('请选择反馈是否有效')
        return
      }
      if(!this.form.processContent){
        this.$message.warning('请输入备注')
        return
      }
      this.$confirm('是否确定提交？若反馈有效，需要修改题目才会发放10个松果！', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        if (!this.editQuestionId) {//添加
          this.$message.warning('数据错误')
          return
        } else {//修改
          // 反馈内容修改掉
          let params={
            userId: this.form.userId,
            id:this.form.id,
            processContent:this.form.processContent,
            processState: this.form.processState
          }
          updatefeedback(params).then(async res => {
            if (res.data.code == 200) {
              this.$message({
                type: 'success',
                message: '操作成功!'
              });
              this.list()
              this.dialogVisible = false
            } else {
              this.$message({
                type: 'error',
                message: res.data.msg
              });
            }
          })
          // 有效才会修改题目
          if (this.form.processState == 9) {
            // 采纳的话给用户添加10个松果
            let paramsSum = {
              userId: this.form.userId,
              accessPassType: 9
            }
            // 验证松果增加了几次，10次就不再增加
            checkPointSum(paramsSum).then(async res => {
              if (res.data && res.data.code == 200) {
                if (res.data.data < 10) {
                  let params = {
                    userId: this.form.userId,
                    point: 10,
                    accessPass: '反馈采纳',
                    accessPassType: 9
                  }
                  addPoint(params).then(res => {
                    if (res.data.code == 200) {
                    }
                  })
                }
              }
            })


            if (this.typeId == 1) {//选择题
              let selectData = this.selectData
              let checkList = this.checkList
              selectData.forEach(item => {
                checkList.forEach(item2 => {
                  if (item.label == item2) {
                    item.code = 1
                  }
                })
              })
              let str = ''
              const titles = selectData.map(item => {
                if (item.code) {
                  str += item.code
                } else {
                  str += 0
                }
                return item.name + item.value
              })
              let len = 8 - str.length
              for (var i = 0; i < len; i++) {
                str += '0'
              }
              const title = titles.join('&&&')
              const params = {
                typeId: this.typeId,
                content: this.content,
                rightKey: str,
                answer: title,
                courseId: this.courseId,
                id: this.editQuestionId,
                levelId: this.levelId
              }
              // console.log(params)
              // return
              const {data} = await qssaveOrUpdate2(params)
              if (data.code == 200) {
                this.$message.success('操作成功')
                this.dialogVisible = false
                this.list()
              } else {
                this.$message.error(data.msg)
              }
            } else if (this.typeId == 2) {//判断题
              let selectData = this.selectData
              selectData.forEach(item => {

                if (item.label == this.radioAnsWer) {
                  item.code = 1
                }

              })
              let str = ''
              const titles = selectData.map(item => {
                if (item.code) {
                  str += item.code
                } else {
                  str += 0
                }
                return item.name + item.value
              })
              let len = 8 - str.length
              for (var i = 0; i < len; i++) {
                str += '0'
              }
              const title = titles.join('&&&')
              const params = {
                typeId: Number(this.typeId),
                content: this.content,
                rightKey: str,
                courseId: this.courseId,
                id: this.editQuestionId,
                levelId: this.levelId,
              }
              // console.log(params)
              // return
              const {data} = await qssaveOrUpdate2(params)
              if (data.code == 200) {
                this.$message.success('操作成功')
                this.dialogVisible = false
                this.list()
              } else {
                this.$message.error(data.msg)
              }
            }
          }

        }
      })
    },
  },
};
</script>

<style lang='less' scoped>
.main {
  // min-height: 800px;
  background: #fff;
  margin: 19px 0;
  padding: 23px;
  border-radius: 14px;
}
.main > div:first-child {
  font-size: 26px;
  // font-weight: bold;
}
.main > div:nth-child(2) {
  display: flex;
  justify-content: space-between;
  height: 34px;
  margin: 18px 0;
  div {
    display: flex;
    img {
      width: 25px;
      height: 25px;
    }
  }
}
/deep/.el-radio-button__inner {
  height: 34px;
  line-height: 10px;
}
.cinput /deep/.el-input__inner {
  height: 34px;
  line-height: 10px;
}
/deep/.el-button--primary {
  height: 34px; line-height: 10px;
}
/deep/.el-table::before {
  height: 0;
}
.ac /deep/.el-input__inner {
  border: none;
  background: #f7f7f7;
  // margin-right: 11px;
}
.red {
  color: #ff1b0b;
}
.dialog-el-row{
  margin-top: 20px;
}
</style>
