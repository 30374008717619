<!-- 题库管理首页 -->
<template>
  <div>
    <div class="main">
      <div style="font-size: 26px; font-weight: bold">导师答疑</div>
      <div>
        <div>
          <el-row>
            <el-col :span="6" style="width: 100%">
              <div class=""  style="width: 200px;font-size: 20px">回答状态：</div>
              <el-select v-model="formTop.answerStatus" placeholder="请选择">
                <el-option
                    v-for="item in answerStatusList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                ></el-option>
              </el-select>
            </el-col>
            <el-col :span="6" style="width: 100%">
              <div class=""  style="width: 200px;font-size: 20px">发布状态：</div>
              <el-select v-model="formTop.answerIsShow" placeholder="请选择">
                <el-option
                    v-for="item in answerIsShowList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                ></el-option>
              </el-select>
            </el-col>
            <el-col :span="6" style="width: 100%">
              <el-button type="primary" @click="reset">重置</el-button>
              <el-button type="primary" @click="search">搜索</el-button>
            </el-col>
          </el-row>
        </div>
      </div>

      <el-table
          :header-cell-style="{ background: '#F7F7F7' }"
          :data="tableData"
          style="width: 100%; min-height: 600px"
          @selection-change="handleSelectionChange"
      >
        <el-table-column
            type="selection"
            align='center'
            width="55"/>
        <el-table-column type="index" width="50"> </el-table-column>
        <el-table-column prop="id" align="center" label="提问id" :show-overflow-tooltip="true" >
        </el-table-column>
        <el-table-column prop="questionTitle" align="center" label="提问标题" :show-overflow-tooltip="true" >
        </el-table-column>
        <el-table-column prop="questionContent" align="center" label="提问内容" :show-overflow-tooltip="true" >
        </el-table-column>
        <el-table-column prop="userName" align="center" label="用户名称" :show-overflow-tooltip="true" >
        </el-table-column>
        <el-table-column prop="ctime" align="center" label="提问时间" :show-overflow-tooltip="true" >
        </el-table-column>
        <el-table-column prop="interestedNum" label="感兴趣数量" align="center" >
        </el-table-column>
        <el-table-column prop="answerStatus" label="状态" align="center">
          <template slot-scope="scope">
            <div>
              {{scope.row.answerStatus==1?'已回答':'未回答'}}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="answerIsShow" align="center" label="是否发布" :show-overflow-tooltip="true" >
          <template slot-scope="scope">
            <div>
              {{ scope.row.answerIsShow == 1 ? '是' : '-' }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="utime" label="处理时间" align="center">
        </el-table-column>
        <el-table-column prop="address" label="操作" align="center" width="200">
          <template slot-scope="scope">
            <div style="display: flex; justify-content: center">
              <el-link type="primary" :underline="false" @click='deal(scope.row)' style="margin:0 20px" v-if="scope.row.answerStatus==0">处理</el-link>
              <el-link type="primary" :underline="false" @click='details(scope.row)' v-if="scope.row.answerStatus==1">详情</el-link>

            </div>
          </template>
        </el-table-column>
      </el-table>
      <div
          style="
          display: flex;
          align-items: center;
          justify-content: flex-end;
        "
      >
        <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageIndex"
            :page-sizes="[10, 15, 20]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
        >
        </el-pagination>
      </div>
    </div>

    <el-dialog
        title="处理" :visible.sync="dialogVisible" width="60%" :before-close="handleClose" :close-on-click-modal="false">
      <el-form :model="form" label-width="100px"  ref="form">
        <el-row class="dialog-el-row">
          <el-col :span="24">
            <div>反馈内容</div>
          </el-col>
          <el-col :span="18" style="display: flex;height: 250px;" >
            <quill-editor style="height:80%;width:100%;" v-model="msg" ref="richAnalysis" :options="options2"
                          @change="quillchange"></quill-editor>
            <input type="file" @change="uploadRichImgMethod" accept='image/*' ref='uploadRichImg' id="uploadRichImg"
                   style="display:none" />
          </el-col>
        </el-row>
        <el-row class="dialog-el-row" style="margin-top: 100px;display: flex;flex-direction: column">
          <el-col :span="6">
            <div>音频上传</div>
          </el-col>
          <el-col :span="18" >
            <input type="file" @change="uploadimgpicUri" id='file2' accept='audio/*' ref='fileimgCourse'
                   style="color:#fff" v-if="!resourceUrl" />
            <div style="display: flex;flex-direction: column;">
              <audio :src="resourceUrl" v-if="resourceUrl" controls></audio>
              <el-link type="primary" :underline="false" @click="resourceUrl = ''" v-if="resourceUrl">删除</el-link>
            </div>
          </el-col>
        </el-row>
        <el-row class="userQw" style="margin-top: 50px;">
          <el-col :span="6">
            <div>是否匿名：</div>
          </el-col>
          <el-col :span="18">
            {{ serExpectation == 0 ? '不匿名' : '匿名' }}
          </el-col>
        </el-row>
        <el-row class="publishOrNot" style="margin-top: 50px;">
          <el-col :span="6">
            <div>是否发布</div>
          </el-col>
          <el-col :span="18">
            <el-switch active-color="#13ce66" v-model="form.answerIsShow">
            </el-switch>
          </el-col>
        </el-row>
        <el-row class="publishOrNot" style="margin-top: 50px;" v-show="form.answerIsShow == true">
          <el-col :span="6">
            <div title="最多20字">答疑标题<span style="color: red">*</span>：</div>
          </el-col>
          <el-col :span="18">
            <el-input v-model="form.questionTitle" maxlength="20" type="text" placeholder="请输入（最多20字）"></el-input>
          </el-col>
        </el-row>
      </el-form>

      <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="submit" v-show="isShow">确 定</el-button>
  </span>
    </el-dialog>

  </div>
</template>
<script type="text/javascript" src="https://unpkg.com/mediainfo.js/dist/mediainfo.min.js"></script>
<script>
  // 工具栏配置
  import uploadImg from "@/utils/uploadImg";

  const toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
  ['blockquote', 'code-block'],

  [{'header': 1}, {'header': 2}],               // custom button values
  [{'list': 'ordered'}, {'list': 'bullet'}],
  [{'script': 'sub'}, {'script': 'super'}],      // superscript/subscript
  [{'indent': '-1'}, {'indent': '+1'}],          // outdent/indent
  [{'direction': 'rtl'}],                         // text direction

  [{'size': ['small', false, 'large', 'huge']}],  // custom dropdown
  [{'header': [1, 2, 3, 4, 5, 6, false]}],

  [{'color': []}, {'background': []}],          // dropdown with defaults from theme
  [{'font': []}],
  [{'align': []}],
  ['link', 'image', 'video'],
  ['clean']                                         // remove formatting button
  ]
  import {
    questiontypeList,
    qsList,
    levelList,
    qssaveOrUpdate,
    PaperList,
    delePaper,
    goodsClassList,
    deleGoodsClass,
    getOpinion,
    updatefeedback,
    qssaveOrUpdate2, addPoint, answerQuestionsList, getCosCredit, audioResource, answerQuestionsDeal, getRichText
  } from "../../apis/index";
  import COS from "cos-js-sdk-v5";
  import uploadRIchImg from "@/utils/uploadRIchImg";
  import uploadRichText from "@/utils/uploadRichText";
export default {
  data() {
    return {
      isShow: false,
      serExpectation: '',
      form: {
        id: '',
        answerIsShow: false,
        questionTitle: '',
        answerAudioTime: 0
      },
      resourceUrl: '',//音频资源
      msg:'',
      options2: {
        theme: "snow",
        placeholder: "请输入内容",
        modules: {
          toolbar: {
            container: toolbarOptions,  // 工具栏
            handlers: {
              'image': function (value) {
                if (value) {
                  document.getElementById('uploadRichImg').click()
                } else {
                  this.quill.format('image', false);
                }
              }
            }
          }
        }
      },
      radioAnsWer: '',//判断题选择
      checkList: [],
      selectData: [
        {
          label: "1",
          name: "A",
          value: "",
        },
        {
          label: "2",
          name: "B",
          value: "",
        },
        {
          label: "3",
          name: "C",
          value: "",
        },
      ],
      dialogVisible:false,
      resourceUriDes:'',
      resourceUri:[],
      levelId:'',
      content:'',
      processContent:'',
      isStatus:0,
      date:'',
      timeState:'',
      typeId: '1',//题目类型
      sellevelId: '',//筛选用的
      levelList: [//层级列表
        {
          name: 'lv1',
          id: 1
        },
        {
          name: 'lv2',
          id: 2
        },
        {
          name: 'lv3',
          id: 3
        },
      ],
      timeStateList:[
        {
          id:1,
          name:'未生效'
        },
        {
          id:2,
          name:'已生效'
        },
        {
          id:3,
          name:'已过期'
        },
      ],
      typeList:[
        {
          id:1,
          name:'小程序'
        },{
          id:2,
          name:'课程包'
        }
      ],
      type:'',//问卷类型
      pageIndex: 1,
      pageSize: 10,
      total:0,
      qslist:[],
      levelLists:[],
      value: "",
      isshow: false,
      isshow2: true,
      isdisabled:true,
      currentPage: 1,
      radio: "1",
      input: "",
      tableData: [

      ],
      answerStatusList:[
          {
            id: '',
            name: '全部'
          },
          {
            id: 0,
            name: '未回答'
          },
          {
            id: 1,
            name: '已回答'
          },
      ],answerIsShowList:[
          {
            id: '',
            name: '全部'
          },
          {
            id: 0,
            name: '未发布'
          },
          {
            id: 1,
            name: '已发布'
          },
      ],
      brandList: [],
      formTop: {
        answerStatus: '',
        answerIsShow: ''
      }
    };
  },
  watch: {
    typeId(newdata, olddate) {//添加试题类型监听
      console.log(newdata);
      if (newdata == 1 && !this.editQuestionId) {
        this.selectData = [
          {
            label: "1",
            name: "A",
            value: "",
          },
          {
            label: "2",
            name: "B",
            value: "",
          },
          {
            label: "3",
            name: "C",
            value: "",
          },
        ];
      } else if (newdata == 2) {
        this.selectData = [
          {
            label: "1",
            name: "正确",
          },
          {
            label: "2",
            name: "错误",
          },
        ];
      }
    },

    coursePageName(newdata, olddata) {
      if (newdata == '') {
        this.getcourseList()
      } else {
        let tableData = this.tableData
        let tableDataNew = tableData.filter(item => {
          return item.record.name.indexOf(newdata) != -1
        })
        this.tableData = tableDataNew
      }
    },
    brandId() {
      this.getCateGorylist().then(res => {
        this.recordId = this.categoryId = this.cateGoryList[0].record.id
        this.getcourseList()
      })
    },
    configValue1(newdata, olddata) {
      if (newdata != 4) {
        this.priceMoney = this.pricePoint = 0
      }

    },
    questionStr(newdata, olddata) {
      this.pageIndex = 1
      console.log(newdata);
      if (newdata) {
        this.isshow = true;
      } else {
        this.questionStr = ''
        this.getQuestionList()
        this.isshow = false;
      }
    },
    dialogVisibleQuestion(newdata) {//监听试题管理关闭重置字段
      if (!newdata) {
        this.questionStr = ''
        this.questionId = ''
      }
    },
    questionId(newdata, olddata) {//监听切换题目类型
      this.questionId = newdata
      this.pageIndex = 1
      this.getQuestionList()
      // if (newdata == "2") {
      //   this.isshow2 = true;
      // } else {
      //   this.isshow2 = false;
      // }
    },
    input(newdata, olddata) {
      if(newdata==''){
        this.isshow=false
        this.input=''
        this.list()
      }else{
        this.isshow=true
      }
    },
    radio(newdata, olddata) {
      this.list()
    },
    value(){
      this.list()
    },
  },
  created(){
    // let date=new Date().toLocaleString( )
    // let date1=date.replace(/\//g,'-')
    // this.date=new Date(date1).getTime()
    // this.questype()
    this.list()
    this.brandList = this.$store.state.brandList
    console.log('this.brandList',this.brandList);
    // this.leave()
  },

  filters:{
    filtersDate(date){
      return new Date(date).getTime()
    },
  },
  methods: {
    uploadRichImgMethod(e) {//上传图片至富文本
      if (this.$refs['uploadRichImg'].files[0]) {
        let selectFileobj = this.$refs['uploadRichImg'].files[0]
        let quill = this.$refs.richAnalysis.quill
        uploadRIchImg(selectFileobj, quill)
      }
    },
    uploadRichImgMethod2(e) {//内容部分上传图片至富文本
      if (this.$refs['uploadRichImg2'].files[0]) {
        let selectFileobj = this.$refs['uploadRichImg2'].files[0]
        let quill = this.$refs.richAnalysis2.quill
        uploadRIchImg(selectFileobj, quill)
      }
    },
    uploadimg3(e){//上传图片
      console.log('我是点击富文本上传图片')
      if(this.$refs['file3'].files[0]){
        let filename = this.$refs['file3'].files[0].name
        let selectFileobj = this.$refs['file3'].files[0]
        // console.log(selectFileobj)
        this.upload3(selectFileobj)

      }
    },
    upload3(selectFileobj){
      let that=this
      let name = selectFileobj.name
      //    if(that.levelId==''){
      //       that.$message.error('请先选择等级')
      //       document.getElementById('file3').value=''
      //       return
      //     }
      let filename ='content/resource/image/'+
          name.slice(0, name.length - 4) +
          new Date().getTime() +
          name.slice(-4);
      console.log(filename)
      var cos = new COS({
        getAuthorization: function (options, callback) {
          getCosCredit().then(result=>{
            var data = result.data.data;
            var credentials = data && data.credentials;
            if (!data || !credentials) return console.error('credentials invalid');
            sessionStorage.setItem('bucket', data.bucket)
            callback({
              TmpSecretId: credentials.tmpSecretId,
              TmpSecretKey: credentials.tmpSecretKey,
              XCosSecurityToken: credentials.sessionToken,
              // 建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
              StartTime: data.startTime, // 时间戳，单位秒，如：1580000000
              ExpiredTime: data.expiredTime, // 时间戳，单位秒，如：1580000900
            });
          })
        }
      });
      cos.options.getAuthorization ('',function(obj){
        cos.putObject({
          Bucket: sessionStorage.getItem('bucket'), /* 必须 */
          Region: 'ap-nanjing',     /* 存储桶所在地域，必须字段 */
          Key: filename,              /* 必须 */
          StorageClass: 'STANDARD',
          Body: selectFileobj, // 上传文件对象
          onProgress: function(progressData) {
            // console.log(JSON.stringify(progressData));
            console.log(progressData)
          }
        }, function(err, data) {
          console.log(err || data);
          if(data){

            // let url='https://'+data.Location
            let url=data.Location.replace(/content-1308336073.cos.ap-nanjing.myqcloud.com/g,'https://sihuankangyuan.cn')
            let quill = that.$refs.richAnalysis.quill
            let length = quill.getSelection().index;
            // 插入图片，url为服务器返回的图片链接地址
            quill.insertEmbed(length, 'image', url)
            // 调整光标到最后
            quill.setSelection(length + 1)
          }
        });
      })

    },
    uploadimgpicUri(e) {//上传音频
      let selectFileobj = this.$refs['fileimgCourse'].files[0]
      if (selectFileobj) {
        uploadImg(selectFileobj, (res) => {
          console.log(res)
          this.resourceUrl = res
          this.countAudioTime(res)
        })
      }
    },
    async countAudioTime(url) {//计算音频时长
      const audio = new Audio()
      audio.src = url
      while (isNaN(audio.duration) || audio.duration === Infinity) {
        // 延迟一会 不然网页都卡死
        await new Promise(resolve => setTimeout(resolve, 200));
        // 设置随机播放时间，模拟调进度条
        audio.currentTime = 10000000 * Math.random();
      }
      this.form.answerAudioTime = parseInt(audio.duration)
      console.log('音频的总时长:', parseInt(audio.duration))
    },
    quillchange(delta, oldDelta, source){
      console.log(delta, oldDelta, source)
    },
    selectQues(item) {
      this.sellevelId = item.id
      // this.getQuestionList()
    },
    details(row){
      debugger
      this.isShow = false
      this.dialogVisible = true
      this.form.id = ''
      this.form.questionTitle = ''
      this.resourceUrl = ''
      this.form.answerIsShow = ''
      this.msg  = ''
      this.form.id = row.id
      this.form.questionTitle = row.questionTitle
      this.resourceUrl = row.answerAudio
      if(row.answerContent){
        getRichText('https://content-1308336073.cos.ap-nanjing.myqcloud.com/'+row.answerContent).then(res => {
          this.msg = res.data
        })
      }
      this.form.answerIsShow = row.answerIsShow == 1 ? true : false

    },
    deal(row) {
      this.dialogVisible = true
      this.form.id = ''
      this.form.questionTitle = ''
      this.resourceUrl = ''
      this.form.answerIsShow = false
      this.msg  = ''
      this.form.id = row.id
      this.isShow = true
    },
    look(item){//查看统计
      console.log(item)
      let items=JSON.stringify(item)
      this.$router.push({path:'/surveyPapgeDetails2',query:{items}})
    },
    async leave() {
      //获取等级列表
      const { data } = await levelList({roleId:3});
      if (data.code == 200) {
        this.levelLists = data.data;
      } else {
        this.$message.error(data.msg);
      }
    },
    async list(){
      const params={
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        answerStatus: this.formTop.answerStatus,
        answerIsShow: this.formTop.answerIsShow
      }
      const {data}=await answerQuestionsList(params)
      if(data.code==200){
        this.tableData=data.data.records
        this.total=Number(data.data.total)
      }
    },
    async questype() {//题目类型列表
      const { data } = await questiontypeList();
      let qslist=data.data
      this.qslist = qslist;

    },
    add(){//添加题目
      this.$router.push({path:'/mallClass'})
    },
    handleClose(){
      this.dialogVisible=false
    },
    des(item){
      this.resourceUriDes=item
    },
    details2(row){//添加试题
      this.$router.push({path:'/surveyPapgeAdd2',query:{row:JSON.stringify(row)}})
    },
    reset(){
      this.formop= {
        answerStatus: '',
        answerIsShow: ''
      }
      this.list()
    },
    search() {
      console.log("我是搜索");
      this.list()
    },
    handleSelectionChange(val) {//选中
      console.log(val)
      if(val.length>0){
        this.isdisabled=false
      }else{
        this.isdisabled=true
      }
    },
    handleSizeChange(val) {
      this.pageSize=val
      console.log(`每页 ${val} 条`);
      this.list()
    },
    handleCurrentChange(val) {
      this.pageIndex=val
      console.log(`当前页: ${val}`);
      this.list()
    },
    dele(row) {
      const params={
        id:row.id,
      }
      this.$confirm('此操作将永久删除该项, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleGoodsClass(params).then(res=>{
          if(res.data.code==200){
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.list()
          }else{
            this.$message({
              type: 'error',
              message: res.data.msg
            });
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    deleall() {
      this.$confirm('此操作将永久删除选中项, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    getBrandName(brandId) {
      const brand = this.brandList.find(item => item.id === brandId);
      return brand ? brand.name : '未知品牌';
    },
    sel(val, index) {
      this.selectData[index].checked = val
      let obj = {
        checked: val,
        label: this.selectData[index].label,
        name: this.selectData[index].name,
        value: this.selectData[index].value,
      }
      this.$set(this.selectData, index, obj)
    },
    deleIndex(ind) {
      this.selectData.splice(ind, 1);
    },
    stripTags(html) {
      // 使用正则表达式匹配 HTML 标签
      let stripped = html.replace(/<[^>]+>/g, '');
      return stripped;
    },
    async submit() { // 保存
        if(this.form.answerIsShow){
          if(!this.form.questionTitle){
              this.$message.warning('发布，标题不可为空！')
              return
          }
        }
      console.log('this.msg =',this.msg )
      console.log('this.resourceUrl =',this.resourceUrl )
        if (!this.msg && !this.resourceUrl) {
          this.$message.warning('请输入回复内容或音频')
          return
        }
        let richTextUrl = ''
        if (this.msg) {
          await uploadRichText(this.msg, async (desUri) => {
            richTextUrl = desUri
            // 数据保存
            let params = {
              id: this.form.id,
              questionTitle: this.form.questionTitle,
              answerAudio: this.resourceUrl,
              answerContent: richTextUrl,
              answerContentClean: this.stripTags(this.msg),
              answerIsShow: this.form.answerIsShow ? 1 : 0,
              answerAudioTime: this.form.answerAudioTime,
            }
            const {data} = await answerQuestionsDeal(params)
            if (data.code == 200) {
              this.$message.success('保存成功')
              this.dialogVisible = false
              this.list()
            }else{
              this.$message.error(data.msg)
            }
          })
        }else{
          // 数据保存
          let params = {
            id: this.form.id,
            questionTitle: this.form.questionTitle,
            answerAudio: this.resourceUrl,
            answerIsShow: this.form.answerIsShow ? 1 : 0,
            answerAudioTime: this.form.answerAudioTime
          }
          const {data} = await answerQuestionsDeal(params)
          if (data.code == 200) {
            this.$message.success('保存成功')
            this.dialogVisible = false
          }
        }

    },
  },
};
</script>

<style lang='less' scoped>
.main {
  // min-height: 800px;
  background: #fff;
  margin: 19px 0;
  padding: 23px;
  border-radius: 14px;
}
.main > div:first-child {
  font-size: 26px;
  // font-weight: bold;
}
.main > div:nth-child(2) {
  display: flex;
  justify-content: space-between;
  height: 34px;
  margin: 18px 0;
  div {
    display: flex;
    img {
      width: 25px;
      height: 25px;
    }
  }
}
/deep/.el-radio-button__inner {
  height: 34px;
  line-height: 10px;
}
.cinput /deep/.el-input__inner {
  height: 34px;
  line-height: 10px;
}
/deep/.el-button--primary {
  height: 34px; line-height: 10px;
}
/deep/.el-table::before {
  height: 0;
}
.ac /deep/.el-input__inner {
  border: none;
  background: #f7f7f7;
}
.red {
  color: #ff1b0b;
}
.dialog-el-row{
  margin-top: 20px;
}
.ql-toolbar.ql-snow + .ql-container.ql-snow {
  height: 200px;
}
.ql-container.ql-snow {
  height: 200px;
}
</style>
